
import model from './model';

export default (state = model.files, action) => {
  switch (action.type) {
    case 'SWITCH_ACCOUNT_PENDING': {
      return {
        ...state,
        ...model.files,
        loading: true,
        loaded: false,
      };
    }
    case 'GET_FILES_FULFILLED': {
      return {
        ...state,
        data: [...state.data, ...action?.payload?.data],
        loading: false,
        loaded: true,
      }
    }
    case 'GET_FILES_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_FILES_REJECTED': {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case 'ADD_FILE':
    case 'MERGE_FILE_CHANGE': {
      return {
        ...state,
        data: action.data
      }
    };
    case 'TOGGLE_SHARE_FILE_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          ShareFileModal: { showing: action.showing, file: action.file }
        }
      };
    }
    default:
      return state;
  }
};
