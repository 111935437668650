
import model from './model';

export default (state = model.projects, action) => {
  switch (action.type) {
    case 'CHANGE_PROJECTS_VIEW': {
      return {
        ...state,
        view: action.view
      };
    }
    case 'CHANGE_PROJECTS_SORT_BY': {
      return {
        ...state,
        sortBy: action.sortBy
      };
    }
    case 'CHANGE_PROJECTS_OWNER': {
      return {
        ...state,
        owner: action.owner
      };
    }
    case 'CHANGE_PROJECTS_MODIFIED': {
      return {
        ...state,
        modified: { ...model.projects.modified, ...action.modified}
      };
    }
    default:
      return state;
  }
};
