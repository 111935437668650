import { chakra } from '@chakra-ui/react'

export const HubspotLogo = '/images/logos/hubspot.webp'

export const PaypalLogo = '/images/logos/paypal.webp'

export const GettyImagesLogo = '/images/logos/getty.webp'

export const UniOfOxfordLogo = '/images/logos/oxford.webp'

export const SquarespaceLogo = '/images/logos/squarespace.webp'

export const AccentureLogo = '/images/logos/accenture.webp'

export const UniOfCambridgeLogo = '/images/logos/cambridge.webp'

export const SlackLogo = '/images/logos/slack.webp'

export const UniOfHavard = '/images/logos/harvard.webp'