import model from './model';

export default (state = model.organization, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATION_FULFILLED': {
      return { ...state, ...action.payload };
    }
    case 'SET_ORGANIZATION_PENDING': {
      return { ...state };
    }
    case 'SET_ORGANIZATION_REJECTED': {
      return { ...state, error: action.payload };
    }
    /*** SWITCHING ***/
    case 'SWITCH_ACCOUNT_FULFILLED': {
      return { ...model.organization };
    }
    /*** SWITCHING ***/

    case 'MERGE_ORGANIZATION_DOC_CHANGES': {
      return {
        id: state.id, ...action.doc
      };
    }

    case 'MERGE_ORGANIZATION_CHANGE': {
      return {
        ...state,
        ...action.data
      }
    };

    default:
      return state;
  }
};
