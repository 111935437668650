const baseColors = ['#F0F8FF', '#D9EEFF', '#C0E3FF', '#A6D7FF', '#9AD2FF', '#8DCCFF', '#66BBFF', '#43A1EC'];

export const model = {
  auth: { switching: {} },
  user: {},
  organization: {
  },
  organizations: {
    loaded: false,
    data: []
  },
  people: {
    loaded: false,
    byId: {},
    data: []
  },
  projects: {
    view: typeof window !== 'undefined' ? localStorage.getItem(`projectsView`) || 'grid' : 'grid',
    owner: null,
    modified: { key: null, title: 'Date modified' },
    sortBy: 'newest', // oldest, a-z, z-a
  },
  folders: {
    loaded: false,
    byId: {},
    data: [],
    ui: {
      NewFolderModal: { showing: false },
      ShareFolderModal: { showing: false },
      DeleteFolderModal: { showing: false }
    }
  },
  files: {
    loaded: false,
    byId: {},
    data: [],
    ui: {
      ShareFileModal: { showing: false }
    }
  },
  sitemap: {
    collaborators: {},
    data: {
      nodes: [],
      links: [],
      heights: {},
      website_sections: {},
      page_sections: {},
      seo: {}
    },
    docs: {
      pages: {},
      website_sections: {},
      sections: {},
      scheduled_screenshots: { pages: {} },
      filesystem: { pages: 'firestore' }, // which docs are saved to file, rather than firestore
    },
    covers: {
      pages: {
        default: 'landing-pages/content-4',
      },
      device: 'desktop'
    },
    pallette: {
      baseColors,
      colors: baseColors,
    },
    comments: {},
    history: { undo: [], redo: [] },
    changes: {
      pages: {
        data: [],
        saving: false,
        error: null,
        errorCount: 0
      },
      website_sections: {
        data: [],
        saving: false,
        error: null,
        errorCount: 0
      },
      sections: {
        data: [],
        saving: false,
        error: null,
        errorCount: 0
      },
      covers: {
        data: [],
        saving: false,
        error: null,
        errorCount: 0
      },
      comments: {
        data: [],
        saving: false,
        error: null,
        errorCount: 0
      }
    },
    ui: {
      SubfoldersTabs: {},
      NotificationsDropdown: { showing: false },
      ContextMenuDropdown: { showing: false, offset: [] },
      PalletteDropdown: { showing: false, header: null },
      PageCommentsDrawer: { showing: false },
      PageCommentsPopover: { offset: [] },
      SelectCoverModal: { showing: {}, capturing: {} },
      ShareModal: { showing: false, error: null },
      OverflowModal: { showing: false, pages: [] },
      FullscreenCoverDrawer: { showing: false, downloading: false },
      ExportPDFDrawer: { showing: false },
      AddCollaboratorForm: {},
      DeleteCoversConfirm: { showing: false },
      SitemapPageLimitMessaging: { showing: false },
      CommentsPopover: { showing: false, view: 'mine', lastOpen: new Date() },
      ChatDrawer: { showing: false, lastOpen: new Date() },
      PageDrawer: { showing: false, page: {} },
      CaptureDrawer: { showing: false },
      RecurringScreenshotsDrawer: { showing: false },
      SaveSitemapModal: { showing: false },
      PageSectionsOptionsPopover: { showing: false },
      WebsiteSectionOptionsPopover: { showing: false },
      WebsiteSectionNewButton: { showing: false },
      PageButtons: { showing: false },
      ExportCoversProgressModal: { showing: false, downloading: true, progress: 0 }
    }
  },
  editor: {
    loading: false,
    loaded: false,
    // shared
    pallette: {
      baseColors,
      colors: baseColors,
    },
    ui: {
      RevisionHistoryDrawer: { showing: false, selectedIndex: 0, editors: {}, changes: [], data: {} },
      CanvasTextEditor: { showing: false, input: 'textarea' },
    },
    utils: {
      zoomLevel: 100
    }
  },
  flow: {
    loading: false,
    loaded: false,
    data: {
      nodes: [],
      links: []
    },
    docs: {
      elements: {},
    },
    history: { undo: [], redo: [] },
    changes: {
      data: [],
      saving: false,
      error: null,
      errorCount: 0
    },
    ui: {
      OptionsPopover: { offset: [], showing: false },
      LinkedPagePopover: { offset: [], showing: false },
      PageDrawer: { showing: false },
      ExportPDFDrawer: { showing: false },
      FlowsSelect: { showing: false },
      SymbolButtons: { showing: false },
    },
    utils: {
      NewSymbol: { dragging: false },
      NewConnectorLine: { showing: false },
      MultiSelectBox: { showing: false },
      mouseoverSymbol: false,
      mouseoverConnector: false,
      mouseoverIcon: false
    }
  },
  sitemaps: {
    imported: {
      byId: {
      },
      loading: false,
      loaded: false
    },
    importing: {
      byId: {
      },
      loading: false,
      loaded: false
    },
    processing: {
      progress: {
        loading: false,
        data: { urls: [] }
      },
      csv: {
        uploading: false,
        progress: 1,
        uploaded: false,
      },
      xml: {
        file: {
          uploading: false,
          progress: 1,
          uploaded: false,
        },
      },
      crawl: {}
    }
  },
  ui: {
    colorMode: "light",
    sideMenu: { collapsed: false },
    sideDrawer: { collapsed: true },
    navbar: { hidden: false },
    OrganizationSettingsModal: { showing: false, users: {}, details: {} },
    DeleteSitemapConfirmationAlert: { showing: false },
    UpgradeModal: { showing: false },
    NewSitemapModal: { showing: false, /* showDetailScreen: true, */ createType: 'history' },
    NewUserFlowModal: { showing: false },
    AddUsersModal: { showing: false },
    TemplateDrawer: { showing: false }
  }
};

export default model;