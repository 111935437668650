import model from './model';

export default (state = model.organizations, action) => {
  switch (action.type) {
    /*** GET ORGANIZATIONS ***/
    case 'GET_ORGANIZATIONS_FULFILLED': {
      return { ...state, loaded: true, data: action.payload }
    }
    case 'GET_ORGANIZATIONS_PENDING': {
      return { ...state }
    }
    case 'GET_ORGANIZATIONS_REJECTED': {
      return { ...state, loaded: true, error: action.payload }
    }
    /*** GET ORGANIZATIONS ***/
    case 'ADD_ORGANIZATION':
    case 'REMOVE_ORGANIZATION': {
      return { ...state, data: action.data }
    }
    // GET RECENT SITEMAPS
    /*case 'SET_RECENT_SITEMAPS_FULFILLED': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.organization]: {
            ...state.byId[action.meta.organization],
            teams: {
              ...state.byId[action.meta.organization].teams,
              [action.meta.team]: {
                ...state.byId[action.meta.organization].teams[
                  action.meta.team
                ],
                sitemaps: {
                  loading: false,
                  loaded: true,
                  byId: action.payload,
                },
              },
            },
          },
        },
      };
    } 
    case 'SET_RECENT_SITEMAPS_PENDING': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.organization]: {
            ...state.byId[action.meta.organization],
            teams: {
              ...state.byId[action.meta.organization].teams,
              [action.meta.team]: {
                ...state.byId[action.meta.organization].teams[
                  action.meta.team
                ],
                sitemaps: {
                  loading: true,
                  loaded: false,
                  error: false,
                },
              },
            },
          },
        },
      };
    }
    case 'SET_RECENT_SITEMAPS_REJECTED': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.organization]: {
            ...state.byId[action.meta.organization],
            teams: {
              ...state.byId[action.meta.organization].teams,
              [action.meta.team]: {
                ...state.byId[action.meta.organization].teams[
                  action.meta.team
                ],
                sitemaps: {
                  loading: false,
                  loaded: true,
                  error: action.error,
                },
              },
            },
          },
        },
      };
    } */
    default:
      return state;
  }
};
