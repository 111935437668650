import { Heading, Stack, Box, useColorModeValue } from "@chakra-ui/react"
import { isInMarketing } from "../../../shared/helpers"

export const Headline = props => {
    const { heading, subheading, subheadingSize, color = 'gray.600', ...rest } = props
    const subheadingColor = useColorModeValue("fg.muted", "fg.default")
    return (
        <Stack
            maxWidth='2xl'
            spacing={rest?.spacing || { base: 6, md: 10 }}
            textAlign={props?.textAlign || { base: "left", md: "center" }}
            {...rest}
        >
            <Heading as="h1" size={props?.size || { base: "md", md: isInMarketing ? "lg": "2xl" }} color="fg.default" fontWeight="semibold" letterSpacing="tight">{heading}</Heading>
            {subheading && <Box maxWidth='2xl' fontSize={subheadingSize || { base: "md", md: isInMarketing ? "xl" : "lg" }} color={subheadingColor}>{subheading}</Box>}
        </Stack>
    )
}