import thunk from 'redux-thunk';
import reduxPromiseMiddleware from 'redux-promise-middleware';
// eslint-disable-line no-unused-vars
import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore'

export default [
  reduxPromiseMiddleware(), // Resolve, reject promises with conditional optimistic updates
  thunk.withExtraArgument({ getFirebase, getFirestore }), // Thunk middleware for Redux
];
