import { combineReducers } from 'redux';

import AuthReducer from './auth-reducer';
import ProjectsReducer from './projects-reducer';
import FilesReducer from './files-reducer';
import FoldersReducer from './folders-reducer';
import OrganizationReducer from './organization-reducer';
import OrganizationsReducer from './organizations-reducer';
import PeopleReducer from './people-reducer';
import EditorReducer from './editor-reducer';
import SitemapReducer from './sitemap-reducer';
import FlowReducer from './flow-reducer';
import SitemapsReducer from './sitemaps-reducer';
import UIReducer from './ui-reducer';
import UserReducer from './user-reducer';
import { responsiveStateReducer } from 'redux-responsive';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import model from './model';

const appReducer = combineReducers({
  auth: AuthReducer,
  organization: OrganizationReducer,
  organizations: OrganizationsReducer,
  people: PeopleReducer,
  projects: ProjectsReducer,
  files: FilesReducer,
  folders: FoldersReducer,
  editor: EditorReducer,
  sitemap: SitemapReducer,
  flow: FlowReducer,
  sitemaps: SitemapsReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  ui: UIReducer,
  browser: responsiveStateReducer,
  user: UserReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'LEFT_EDITOR') {
    state = {
      ...state,
      editor: model.editor,
      sitemap: model.sitemap,
      flow: model.flow,
      ui: {
        ...state.ui,
        sideMenu: { collapsed: false },
        sideDrawer: { collapsed: true },
        navbar: { hidden: false },
      },
    };
  }
  if (action.type === 'INIT_EDITOR_REJECTED') {
    state = {
      ...state,
      editor: {
        ...state.editor,
        loading: false,
        loaded: true,
        error: action.payload.message
      },
      ui: {
        ...state.ui,
        // sideMenu: { collapsed: false },
      },
    };
  }
  if (action.type === 'LOGOUT_FULFILLED') { state = { ...state, ...model } };
  /*** ORGANIZATION SETTINGS MODAL ***/
  if (action.type === 'ADD_NEW_USER_TO_ORGANIZATION_REJECTED') {
    state = {
      ...state,
      ui: {
        ...state.ui,
        OrganizationSettingsModal: {
          ...state.ui.OrganizationSettingsModal,
          users: {
            ...state.ui.OrganizationSettingsModal.users,
            error: action.payload.message
          }
        },
      },
    };
  };
  if (action.type === 'DELETE_USER_SITEMAP_FULFILLED') {
    const { team } = state.user.token.claims;
    state = {
      ...state,
      firestore: {
        ...state.firestore,
        data: {
          ...state.firestore.data,
          teams: {
            ...state.firestore.data.teams,
            [team]: {
              ...state.firestore.data.teams[team],
              sitemaps: {
                ...state.firestore.data.teams[team].sitemaps,
                active: []
              }
            }
          }
        },
        ordered: {
          ...state.firestore.ordered,
          teams: [
            {
              ...state.firestore.ordered[0],
              sitemaps: {
                ...state.firestore.ordered[0]?.sitemaps,
                active: []
              }
            }
          ]
        }
      }
    };
  };
  if (action.type === 'UPDATE_SITEMAP_THUMBNAIL_FULFILLED') {
    state = {
      ...state,
      sitemap: {
        ...state.sitemap,
        thumbnail: { ...action.payload?.thumbnail }
      },
      files: {
        ...state.files,
        data: state.files.data.map(file => {
          if (file.id === state.sitemap?.id) {
            return {
              ...file,
              thumbnail: { ...file.thumbnail, ...action.payload?.thumbnail }
            }
          }
          return file;
        })
      }
    }
  }
  /*** ORGANIZATION SETTINGS MODAL ***/
  return appReducer(state, { ...action, state });
};