export const isInMarketing = process.env.isNext

export const toTitleCase = (str = "") => {
    return (str || "")?.replace(/(^|\s)\S/g, (t) => t?.toUpperCase());
}

export const wait = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getEnv = () => {
    // app
    const isInMarketing = process.env.isNext
    if (!isInMarketing) return import.meta.env.VITE__ENVIRONMENT
    // marketing
    const env = process.env.NODE_ENV
    if (env === 'development') return 'dev'
    if (env === 'production') return 'prod'
    return 'dev'
}

export const getShortPlanId = (planId) => planId?.replace("rarchy-", "")?.split("-")?.[0]
export const getInProPlan = (planId) => ['pro', 'freelance'].includes(planId);

export const isInProd = getEnv() === 'prod'
export const isInDev = getEnv() === 'dev'

export const HUBSPOT_PORTAL_ID = isInProd ? 2019947 : 44933480
export const HUBSPOT_SIGNUP_FORM_ID = isInProd ? 'c1b71657-90a9-42b8-af75-84fcbfdce68e' : 'b9ce9c9f-b222-4f82-abbf-973d507d6531'
export const HUBSPOT_CONTACT_FORM_ID = '595bddb9-3994-4550-8d9c-83a84814afba'