import model from './model';

export default (state = model.editor, action) => {
    switch (action.type) {

        case 'CHANGE_EDITOR_PALLETTE': {
            return {
                ...state,
                pallette: action.pallette
            }
        }
        case 'INIT_EDITOR_PENDING': {
            return { ...state, ...action.meta }
        }

        case 'INIT_EDITOR_DOC_FULFILLED': {
            return {
                ...state,
                ...action.payload,
                loading: false,
                loaded: true
            };
        }
        case 'INIT_EDITOR_DOC_PENDING': {
            return {
                ...state,
                ...action.meta,
                loading: true
            };
        }
        case 'INIT_EDITOR_DOC_REJECTED': {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.payload.message
            };
        }

        case 'UPDATE_EDIT_LEVEL': {
            return {
                ...state,
                editLevel: action.editLevel
            }
        }

        case 'SET_ZOOM_LEVEL': {
            return {
                ...state,
                utils: {
                    ...state.utils,
                    zoomLevel: action.zoomLevel
                }
            };
        }

        /*** Revision History ***/

        case 'TOGGLE_REVISION_HISTORY_DRAWER': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    RevisionHistoryDrawer: {
                        ...state.ui.RevisionHistoryDrawer,
                        showing: action.showing,
                        selectedIndex: action.showing ? 0 : null,
                        changes: [], // reset changes,
                        data: {
                            root: null, // reset root
                        },
                        docs: {
                            ...state.ui.RevisionHistoryDrawer.docs,
                            pages: action.showing ? action.state.sitemap?.docs?.pages : {}, // set pages to default when first showing (as selectedIndex = 0)
                            website_sections: action.showing ? action.state.sitemap?.docs?.website_sections : {}, // set pages to default when first showing (as selectedIndex = 0)
                            sections: action.showing ? action.state.sitemap?.docs?.sections : {}, // set sections to default when first showing (as selectedIndex = 0)
                            elements: action.showing ? action.state.flow?.docs?.elements : {}
                        },
                        flow: action.showing && action.state?.flow?.id
                            ?
                            { ...action?.state?.flow, history: model.flow.history }
                            :
                            {}, // need this here as original flow data gets overriden (is mutable and can't seem to fix)
                        retrieving: false,
                        retrieved: false,
                        restoring: false,
                        restored: false
                    },
                }
            };
        }

        case 'SET_REVISION_HISTORY_DRAWER_SELECTED_INDEX': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    RevisionHistoryDrawer: {
                        ...state.ui.RevisionHistoryDrawer,
                        selectedIndex: action.selectedIndex,
                        data: {
                            ...state.ui.RevisionHistoryDrawer.data,
                            ...model.flow.data,
                            root: action.root ? action.root : state.ui.RevisionHistoryDrawer.data.root,
                        },
                        docs: {
                            ...state.ui.RevisionHistoryDrawer.docs,
                            pages: action.pages ? action.pages : state.ui.RevisionHistoryDrawer.docs?.pages,
                            website_sections: action.website_sections ? action.website_sections : state.ui.RevisionHistoryDrawer.docs?.website_sections,
                            sections: action.sections ? action.sections : state.ui.RevisionHistoryDrawer.docs?.sections,
                            elements: action.elements ? action.elements : state.ui.RevisionHistoryDrawer.flow?.docs?.elements,
                        },
                    },
                }
            };
        }

        case 'RETRIEVE_REVISION_HISTORY_DRAWER_CHANGES_PENDING': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    RevisionHistoryDrawer: {
                        ...state.ui.RevisionHistoryDrawer,
                        retrieving: true
                    },
                },
            };
        }

        case 'RETRIEVE_REVISION_HISTORY_DRAWER_CHANGES_FULFILLED': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    RevisionHistoryDrawer: {
                        ...state.ui.RevisionHistoryDrawer,
                        ...action.payload,
                        retrieving: false,
                        retrieved: true
                    },
                },
            };
        }

        case 'RETRIEVE_REVISION_HISTORY_DRAWER_CHANGES_REJECTED': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    RevisionHistoryDrawer: {
                        ...state.ui.RevisionHistoryDrawer,
                        retrieving: false,
                        error: action.payload.code
                    },
                },
            };
        }

        case 'RESTORE_REVISION_HISTORY_DRAWER_CHANGES_PENDING': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    RevisionHistoryDrawer: {
                        ...state.ui.RevisionHistoryDrawer,
                        error: null,
                        restoring: true,
                    },
                },
            };
        }

        case 'RESTORE_REVISION_HISTORY_DRAWER_CHANGES_FULFILLED': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    RevisionHistoryDrawer: {
                        ...state.ui.RevisionHistoryDrawer,
                        restoring: false,
                        restored: true
                    },
                }
            };
        }

        case 'RESTORE_REVISION_HISTORY_DRAWER_CHANGES_REJECTED': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    RevisionHistoryDrawer: {
                        ...state.ui.RevisionHistoryDrawer,
                        restoring: false,
                        restored: false,
                        error: action.error
                    },
                },
            };
        }

        /*** Revision History ***/

        case 'TOGGLE_CANVAS_TEXT_EDITOR': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    CanvasTextEditor: {
                        ...state.ui.CanvasTextEditor,
                        showing: action.showing,
                        node: action.node,
                        section: action.section,
                        newString: action.newString
                    }
                }
            };
        }

        case 'SET_CANVAS_TEXT_EDITOR_INPUT_VAL': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    CanvasTextEditor: {
                        ...state.ui.CanvasTextEditor,
                        newString: action.newString,
                    }
                }
            };
        }

        case 'SAVE_COLOR_LABEL_CHANGE_PENDING': {
            return {
                ...state,
                colors: { ...state.colors, ...action.meta }
            };
        }
        default:
            return state;
    }
};