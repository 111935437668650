import model from './model';

export default (state = model.ui, action) => {
  switch (action.type) {
    case 'UPDATE_COLOR_MODE': {
      return {
        ...state,
        colorMode: action.colorMode
      };
    }
    // TOGGLE SIDEMENU
    case 'TOGGLE_SIDEMENU': {
      return {
        ...state,
        sideMenu: {
          ...state.sideMenu,
          collapsed: action.collapsed,
        },
      };
    }
    // TOGGLE DRAWER
    case 'TOGGLE_SIDEDRAWER': {
      return {
        ...state,
        sideDrawer: { ...state.sideDrawer, collapsed: action.collapsed },
      };
    }
    case 'TOGGLE_SITEMAPS_NAVBAR': {
      return {
        ...state,
        navbar: { ...state.navbar, hidden: action.hidden },
      };
    }
    /*** ORGANIZATION SETTINGS MODAL ***/
    case 'SHOW_ORGANIZATION_SETTINGS_MODAL': {
      return {
        ...state,
        OrganizationSettingsModal: {
          ...state.OrganizationSettingsModal,
          showing: true,
          id: action.id,
          name: action.name,
          tab: action.tab,
          window: action.window
        },
      };
    }
    case 'HIDE_ORGANIZATION_SETTINGS_MODAL': {
      return {
        ...state,
        OrganizationSettingsModal: {
          showing: false,
          ...model.ui.OrganizationSettingsModal
        },
      };
    }
    case 'CHANGE_ORGANIZATION_SETTINGS_MODAL_TAB': {
      return {
        ...state,
        OrganizationSettingsModal: {
          ...state.OrganizationSettingsModal,
          tab: action.tab,
          window: action.window ? action.window : state.OrganizationSettingsModal.window
        },
      };
    }
    case 'CHANGE_ORGANIZATION_SETTINGS_MODAL_WINDOW': {
      return {
        ...state,
        OrganizationSettingsModal: {
          ...state.OrganizationSettingsModal,
          window: action.window,
        },
      };
    }
    case 'CLEAR_ADD_USER_ERROR': {
      return {
        ...state,
        OrganizationSettingsModal: {
          ...state.OrganizationSettingsModal,
          users: {}
        },
      };
    }
    /*** ORGANIZATION SETTINGS MODAL ***/
    case 'TOGGLE_UPGRADE_MODAL': {
      return {
        ...state,
        UpgradeModal: {
          showing: action.showing,
          planId: action.planId,
          lockPlan: action.lockPlan,
          stripeRedirectAttrs: action.stripeRedirectAttrs,
          screen: action.screen
        },
      };
    }
    case 'SET_UPGRADE_MODAL_SCREEN': {
      return {
        ...state,
        UpgradeModal: { ...state.UpgradeModal, screen: action.screen, lockPlan: action.lockPlan },
      };
    }
    case 'TOGGLE_NEW_SITEMAP_MODAL': {
      return {
        ...state,
        NewSitemapModal: {
          createType: action.createType,
          fromHistory: action.fromHistory,
          showDetailScreen: action.showDetailScreen,
          detailId: action.detailId,
          prefill: action.prefill,
          showing: action.showing,
        }
      };
    }
    case 'TOGGLE_NEW_USER_FLOW_MODAL': {
      return {
        ...state,
        NewUserFlowModal: { showing: action.showing }
      };
    }
    case 'INIT_EDITOR_PENDING': {
      return {
        ...state,
        sideMenu: { collapsed: true }
      };
    }
    case 'TOGGLE_ADD_USERS_MODAL': {
      return {
        ...state,
        AddUsersModal: { showing: action.showing }
      };
    }
    case 'TOGGLE_TEMPLATE_DRAWER_PENDING': {
      return {
        ...state,
        TemplateDrawer: {
          showing: action.meta?.showing,
          template: action.meta?.template,
          sitemap: {
            ...model?.sitemap, ...action.meta?.sitemap
          }
        }
      };
    }
    case 'TOGGLE_TEMPLATE_DRAWER_FULFILLED': {
      return {
        ...state,
        TemplateDrawer: {
          ...state.TemplateDrawer,
          sitemap: {
            ...state.TemplateDrawer?.sitemap,
            loading: false,
            loaded: true,
            data: {
              ...state.TemplateDrawer?.sitemap?.data,
              ...action.payload
            }
          }
        }
      };
    }
    case 'INIT_SITEMAP_COVERS_COLLECTIONS': {
      return {
        ...state,
        TemplateDrawer: {
          ...state.TemplateDrawer,
          sitemap: {
            ...state.TemplateDrawer?.sitemap,
            covers: { ...state.TemplateDrawer?.sitemap, collections: action.collections }
          }
        }
      }
    }
    case 'SET_NODES_AND_LINKS': {
      return {
        ...state,
        TemplateDrawer: state?.TemplateDrawer?.showing
          ? {
            ...state.TemplateDrawer,
            sitemap: {
              ...state.TemplateDrawer?.sitemap,
              data: {
                ...state.TemplateDrawer?.sitemap?.data,
                nodes: action.nodes,
                links: action.links
              }
            }
          } : state.TemplateDrawer
      }
    }
    case 'SET_NODE_HEIGHTS': {
      return {
        ...state,
        TemplateDrawer: state?.TemplateDrawer?.showing
          ? {
            ...state.TemplateDrawer,
            sitemap: {
              ...state.TemplateDrawer?.sitemap,
              data: {
                ...state.TemplateDrawer?.sitemap?.data,
                heights: action.heights
              }
            }
          } : state.TemplateDrawer
      }
    }
    case 'SET_WEBSITE_SECTIONS': {
      return {
        ...state,
        TemplateDrawer: state?.TemplateDrawer?.showing
          ? {
            ...state.TemplateDrawer,
            sitemap: {
              ...state.TemplateDrawer?.sitemap,
              data: {
                ...state.TemplateDrawer?.sitemap?.data,
                website_sections: action.sections
              }
            }
          } : state.TemplateDrawer
      }
    }

    case 'INIT_SITEMAP_SECTIONS_DOC_FULFILLED': {
      return {
        ...state,
        TemplateDrawer: state?.TemplateDrawer?.showing
          ? {
            ...state.TemplateDrawer,
            sitemap: {
              ...state?.TemplateDrawer?.sitemap,
              docs: { ...state.TemplateDrawer?.sitemap?.docs, ...action.payload.docs },
              data: { ...state.TemplateDrawer?.sitemap?.data, page_sections: action.payload.data.sections },
            },
          } : state.TemplateDrawer
      }
    }

    case 'SET_ROOT': {
      return {
        ...state,
        TemplateDrawer: state?.TemplateDrawer?.showing
          ? {
            ...state.TemplateDrawer,
            sitemap: {
              ...state?.TemplateDrawer?.sitemap,
              data: { ...state.TemplateDrawer?.sitemap?.data, root: action.root },
            },
          } : state.TemplateDrawer
      }
    }

    case 'INIT_SITEMAP_COVERS_DOC_FULFILLED': {
      return {
        ...state,
        TemplateDrawer: state?.TemplateDrawer?.showing
          ? {
            ...state.TemplateDrawer,
            sitemap: {
              ...state?.TemplateDrawer?.sitemap,
              covers: { ...state.covers, ...action.payload },
            },
          } : state.TemplateDrawer
      }
    }

    // sitemap is always updated when editor is updated, but not vice versa
    case 'CHANGE_EDITOR_PALLETTE':
    case 'CHANGE_SITEMAP_PALLETTE': {
      return {
        ...state,
        TemplateDrawer: state?.TemplateDrawer?.showing
          ? {
            ...state.TemplateDrawer,
            sitemap: {
              ...state?.TemplateDrawer?.sitemap,
              pallette: action.pallette
            },
          } : state.TemplateDrawer
      }
    }

    case 'TOGGLE_ONBOARDING_MODAL': {
      return {
        ...state,
        OnboardingModal: { showing: action.showing, key: action.key }
      };
    }

    default:
      return state;
  }
};
