import { cloneDeep, compact, groupBy, inRange, isEmpty, keys, orderBy, sortBy, take, uniq, uniqBy } from 'lodash';
import { convertObjToArrayWithKey, defaultPalletteColors, getDecodedEmail, getDoesUserHavePathToFolder, getEditorCollectionFromPath, getEditorDocIdFromPath, getEditorSubfolderFromDoc, getEncodedEmail, getFolderIdFromPath, getInEditor, getInSitemap, getInTrash, getInUserFlow, getIsLinkedUserFlowPage, getIsPublicFolderOrSharedWithUser, getIsTemplateDrawerOpen, getIsUserFlowLinkedToSitemap, getIsViewingFolder, getItemNameFromDoc, getLocation, getOrganization, getOrganizations, getPageCommentsDrawer, getPageDrawer, getPeople, getPeopleData, getPhotoURL, getRevisionHistoryDrawer, getUser, getUserId, toTitleCase } from "../helpers/";
import { getCanCommentInEditor, getCanEditInEditor, getCanFullyEditFile } from "../screens/Editor/Navbar/helpers.js";
import { useEffect, useMemo, useRef, useState } from "react";

import deepmerge from 'deepmerge';
import { getInProPlan } from "../../shared/helpers";
import queryString from 'query-string';
import sortArray from "sort-array";
import { useGetNumberOfSeatsIncludedInPlan } from "../../shared/hooks/pricing";
import { useGetShortPlanId } from "../../shared/hooks";
import { useSelector } from 'react-redux';

export const useHasFreeUserNotCreatedFileYet = (collection) => {
    const filesData = useGetFilesData()
    const userId = useGetUserId()
    const inOrganization = useInOrganization()
    return !inOrganization && isEmpty(filesData?.filter(f => f?.collection === collection && f.createdBy === userId))
}

export const useGetSitemapImportHistory = () => {
    return useGetSitemaps('imported')
}

export const useGetNewSitemapModal = () => {
    return useGetUi()?.NewSitemapModal
}

export const useGetOnboardingModal = () => {
    return useGetUi()?.OnboardingModal
}

export const useGetLocation = () => getLocation()

export const useGetProjectsPathFilter = () => {
    const { pathname } = useGetLocation()
    return pathname?.split("/")?.[3]
}

export const useGetProjects = () => {
    return useSelector(state => state.projects)
}

export const useInHomeScreen = () => {
    return useGetLocation()?.pathname?.replace(/\/$/, "") === "/app" // check removes trailing slash
}

export const useInProjectsGridView = () => {
    return useGetProjectsView() === 'grid'
}

export const useGetProjectsView = () => {
    const inHome = useInHomeScreen()
    const { view } = useGetProjects()
    return inHome ? 'grid' : view
}

export const useGetProjectsSortFilter = (key) => {
    return useGetProjects()?.[key]
}

export const useInTrash = () => getInTrash()

export const useGetEditorCollectionFromPath = () => getEditorCollectionFromPath()

export const useGetEditorDocIdFromPath = () => getEditorDocIdFromPath()
export const useInSitemap = () => getInSitemap()
export const useInUserFlow = () => getInUserFlow()

export const useInEditor = () => getInEditor()

export const useGetEditor = () => {
    return useSelector(state => state.editor)
}

export const useInProjects = () => {
    const { pathname } = useGetLocation()
    return [/projects/, /folder/].some(re => re.test(pathname));
}

export const useInHelp = () => {
    const { pathname } = useGetLocation()
    return [/help/].some(re => re.test(pathname));
}

export const useInSettings = () => {
    const { pathname } = useGetLocation()
    return [/settings/].some(re => re.test(pathname));
}

export const useGetHeadingTitleFromSubfolder = () => {
    const subfolder = toTitleCase(useGetSubfolder())
    const title = subfolder.replaceAll("-", " ")
    return title;
}

export const useGetSubfolder = () => {
    const { pathname } = useGetLocation()
    return compact(pathname.split("/"))?.pop();
}

export const useGetSubfolderDepth = () => {
    const { pathname } = useGetLocation()
    return compact(pathname.split("/"))?.length - 1 // -1 because we don't include /app
}

export const useGetSitemaps = (key) => {
    return useSelector(state => state.sitemaps?.[key]);
}

export const useGetFirebaseFromState = () => {
    return useSelector(state => state.firebase);
}

export const useGetRedirectUserQueryParams = () => {
    const auth = useGetAuth();
    const parsed = ({ decode }) => queryString.parse((auth?.redirectUser || window.location.href)?.split("?").pop() || {}, { decode });
    const { id, i: invitedBy, k: key, n: name, m: message, p: permission } = parsed({ decode: true });
    const { e: email } = parsed({ decode: false });
    return { id, email, invitedBy, key, name, message, permission }
}

export const useIsSigningUpOrLoggingIn = () => {
    const auth = useGetAuth();
    return useIsSigningUp() || auth?.loggingIn || auth?.loggedIn
}

export const useIsSigningUp = () => {
    const auth = useGetAuth();
    return auth?.signingUp
}

export const useJustLoggedIn = () => {
    const auth = useGetAuth()
    const prevAuth = usePrevious(auth);
    const loggedIn = auth?.loggedIn;
    const prevLoggedIn = prevAuth?.loggedIn;
    if (loggedIn && !prevLoggedIn) return true;
}

export const useGetFullscreenCoverDrawer = () => {
    const sitemap = useGetSitemap()
    return sitemap?.ui?.FullscreenCoverDrawer;
}

export const useGetSubscription = () => {
    // use helper instead of hook, as shared with marketing
    return getOrganization()?.subscription || {};
}

export const useGetPeople = () => getPeople();

export const useGetFilteredPeopleData = ({ folder, file, forShareFile }) => {

    // used to show avatar group in projects view + shared file people list
    // forShareFile is passed when showing share file modal

    const people = getPeopleData()
    const inOrganization = useInOrganization()
    const organization = useGetOrganization()
    const folders = useGetFolders()?.byId
    // get files folders (to check if in shared folder)
    const fileFolder = file ? folders?.[file?.team] : null
    // filtered
    let filtered = [...people].map(p => {
        const item = folder || file
        const owner = item?.createdBy === p?.id
        p.owner = owner
        return p;
    }).filter(p => {
        const encodedId = getEncodedEmail(p?.email || p?.id) // look for email first (otherwise shared emails where a person with id already retrieved don't get included in this filter)
        if (p.owner) return p;
        if (folder) { if (folder?.members?.includes(encodedId)) return p }
        if (file) {
            const collaborator = file?.collaborators?.[encodedId]
            if (collaborator) {
                p.access = collaborator.access;
                return p;
            }
        }
    });

    // sort by owner
    const data = orderBy(filtered, ['owner'], ['desc']);

    // if folder and public, insert org at start
    // if file and in public folder, insert org at start (if not used for share file modal)
    if (inOrganization) {
        if (!forShareFile) {
            const folderToCheckIfPublic = folder || fileFolder
            if (folderToCheckIfPublic?.members === null) data.unshift({ ...organization, type: 'organization' })
        }
    }

    // return
    return data
}

export const useGetUsers = () => {
    const users = useSelector(state => state.users);
    return users.byId;
}

export const useHasTeamMembers = () => useGetOrganizationUsers()?.length > 1

export const useGetUser = () => getUser()

export const useGetClaims = () => {
    const user = useGetUser();
    return user?.token?.claims || {};
}

export const useGetUserFlow = () => {
    const flow = useSelector(state => state.flow);
    return flow;
}

export const useGetSitemap = () => {
    return useSelector(state => state.sitemap)
}

export const useGetSitemapById = (sitemapId) => {
    const data = useSelector(state => state?.sitemaps?.active?.data);
    return useMemo(() => {
        const sitemap = data?.find(s => s?.id === sitemapId);
        if (sitemap) {
            sitemap.editLevel = "full" // if sitemap is in active sitemaps, user will be part of the team
            return sitemap;
        }
    }, [data])
}

export const useGetUserId = () => {
    const claims = useGetClaims()
    return claims?.user_id
}

export const useIsOrganizationAdmin = () => {
    // return false; // for testing
    const claims = useGetClaims()
    return claims?.organization && claims.role === 'admin'
}

export const useInOrganization = () => {
    return useGetClaims().organization ? true : false;
}

export const useGetOrganization = () => {
    return useSelector(state => state.organization);
}

export const useGetOrganizationUsers = () => {
    const userId = useGetUserId()
    const organization = useGetOrganization()
    const users = convertObjToArrayWithKey(organization?.users)
    const people = getPeopleData()
    return orderBy(users?.map(user => {
        const userId = getDecodedEmail(user?.id)
        const person = people?.find(p => [p?.id, p?.email].includes(userId))
        return { ...deepmerge(person || {}, user || {}), id: user?.id } // ensure we use users id
    }), [u => u?.owner, u => u?.id === userId, 'firstName', 'email'], ['asc', 'desc', 'asc', 'asc'])
    // owner first, then current user, then by first name / email
}

export const useGetAccounts = () => {
    const user = useGetUser()
    const organizations = useGetOrganizationsData()
    const accountId = useGetAccountId()
    const accounts = [
        {
            ...user,
            type: 'user',
            isCurrent: user?.id === accountId
        },
        ...compact(cloneDeep(organizations)?.map(org => {
            if (org) {
                org.type = 'organization';
                org.isCurrent = org?.id === accountId;
            }
            return org
        }))
    ]
    return sortBy(accounts, 'name')
}

// CAN'T be through hook
export const useGetOrganizations = () => getOrganizations()

export const useGetOrganizationsData = (opts = {}) => {
    const organizations = useGetOrganizations()
    const { onlyArchived } = opts
    if (onlyArchived) return organizations?.data?.filter(o => o.archived)
    return organizations?.data?.filter(o => !o.archived) // return "active" (will include unpaid) as default
}

export const useIsMemberOfOrganizations = () => {
    const organizations = useGetOrganizations()
    return (!organizations.loaded || (organizations.loaded && !isEmpty(organizations?.data)))
}

export const useIsCreatorOfOrganization = (opts = {}) => {
    const { onlyActive } = opts
    const userId = useGetUserId()
    const organizations = useGetOrganizations()
    return organizations?.data?.filter(o => onlyActive ? !o.archived : o).find(o => o.createdBy === userId)
}

export const useIsUserFile = (file) => {
    const user = useGetUser()
    return useInUserEditorDoc() || file?.id === user?.id
}

export const useInUserEditorDoc = () => {
    const editor = useGetEditor()
    const user = useGetUser()
    return editor?.id === user?.id
}

export const useIsViewingFolder = () => {
    return getIsViewingFolder()
}

export const useGetFolderIdFromPath = (opts) => {
    return getFolderIdFromPath(opts)
}

export const useInDemoProject = () => {
    const sitemap = useGetSitemap();
    return sitemap?.id === 'fuy2mjJWjKIof5oiacCs'
}

export const useCanFullyEditFile = (doc) => {
    return getCanFullyEditFile(doc)
}
export const useCanEditFile = (doc) => {
    return getCanEditInEditor(doc)
}
export const useCanCommentOnFile = (doc) => {
    return getCanCommentInEditor(doc)
}

export const useIsFilesEmpty = () => {
    return isEmpty(useGetFilesData())
}

export const useHasLoadedFolders = () => {
    return useGetFolders()?.loaded
}

export const useHasLoadedFiles = () => {
    return useGetFiles()?.loaded
}

export const useHasUserCreatedFile = ({ files } = {}) => {
    const userId = useGetUserId()
    const filesData = useGetFilesData()
    return !isEmpty(files || filesData?.filter(f => f.createdBy === userId))
}

export const useHasFreeUserCreatedFile = (collection) => {
    const userId = useGetUserId()
    const inOrganization = useInOrganization()
    const filesData = useGetFilesData()
    return !inOrganization && !isEmpty(filesData?.filter(f => f?.collection === collection && f.createdBy === userId))
}

export const useInProPlan = (planId) => {
    const plan = useGetShortPlanId(planId)
    return getInProPlan(plan)
}

export const useInTeamPlan = (planId) => {
    const plan = useGetShortPlanId(planId)
    return ['team'].includes(plan);
}

export const useInEnterprisePlan = (planId) => {
    const plan = useGetShortPlanId(planId)
    return useInTeamPlan(planId) || ['enterprise'].includes(plan);
}

export const useInAgencyPlan = (planId) => {
    const plan = useGetShortPlanId(planId)
    return ['agency'].includes(plan);
}

export const useGetNumberOfAdditionalSeats = () => {
    const subscription = useGetSubscription()
    return subscription?.add_ons?.seats?.quantity || 0
}

export const useGetTotalNumberOfSeats = ({ planId } = {}) => {
    return useGetNumberOfSeatsIncludedInPlan({ planId }) + useGetNumberOfAdditionalSeats()
}

export const useGetRevisionHistoryDrawer = () => {
    return getRevisionHistoryDrawer()
}

export const useIsPageCommentsDrawerShowing = () => {
    return useGetPageCommentsDrawer().showing
}

export const useGetPageCommentsDrawer = () => {
    return getPageCommentsDrawer()
}

export const useIsPageDrawerShowing = () => {
    return useGetPageDrawer().showing
}

export const useGetPageDrawer = () => {
    return getPageDrawer()
}

export const useInRevisionHistory = () => {
    return useGetRevisionHistoryDrawer().showing
}

export const useIsEditorDocAvailable = () => {
    const editor = useGetEditor();
    if (editor?.archived || editor?.error) return false;
    return true;
}

export const useGetAccountId = () => {
    const organization = useGetOrganization();
    const user = useGetUser();
    return organization?.id || user?.id;
}

export const useHasFolders = () => {
    const data = useGetFoldersData()
    if (!useHasFoldersLoaded()) return true // default is true to wait for loading
    return !isEmpty(data)
}

export const useGetFolders = () => {
    return useSelector(state => state.folders)
}

export const useGetFolderById = (folderId) => {
    return useGetFolders()?.byId?.[folderId]
}

export const useHasFoldersLoaded = () => {
    return useGetFolders()?.loaded
}

export const useGetFoldersData = (opts = {}) => {
    const { forRecent, ignoreFolderId } = opts
    const data = convertObjToArrayWithKey(useGetFolders()?.byId); // useGetFolders()?.data
    return useGetSortedByData({ data, forRecent, ignoreFolderId })
}

export const useGetStarredFolders = () => {
    const user = useGetUser();
    return useGetFoldersData()?.filter(f => user?.starred?.folders?.includes(f.id))
}

export const useGetEditorSubfolderFromDoc = (item) => {
    return getEditorSubfolderFromDoc(item)
}

export const useGetItemNameFromDoc = (item) => {
    return getItemNameFromDoc(item)
}

export const useFolderHasSubfolders = (opts) => {
    let { folderId } = opts || {}
    return !isEmpty(useGetFoldersByKeyOrPath({ key: "team", folderId }))
}

export const useGetAllTopLevelFolders = () => {
    const folders = useGetFoldersData()
    return folders?.filter(f => !f?.team)
}

export const useGetAllSharedFolders = () => {
    const folders = useGetFoldersData()
    const userId = useGetUserId()
    return folders?.filter(f => f.createdBy !== userId ? f : null)
}

export const useGetFoldersByKeyOrPath = (opts) => {

    let { key = "team", pathFilter, ignoreFolderId, folderId } = opts || {}

    // ignoreFolderId still adheres to pathFilters, but NOT modified/owner (used for sidemenu)
    const folderIdFromPath = useGetFolderIdFromPath()
    folderId = ignoreFolderId ? null : (folderId || folderIdFromPath)

    const folders = useGetFoldersData({ ignoreFolderId })
    const foldersById = useGetFolders()?.byId

    const inProjects = !folderId
    // modified filters
    const projectsSortFilterMod = useGetProjectsSortFilter('modified')
    const modified = ignoreFolderId ? "" : projectsSortFilterMod
    // owner filter
    const projectsSortFilterOwn = useGetProjectsSortFilter('owner')
    const owner = ignoreFolderId ? "" : projectsSortFilterOwn

    const projectsPathFilter = useGetProjectsPathFilter()
    const hasPathFilter = pathFilter || projectsPathFilter

    // only return top level folders, or if user doesn't have path to shared folder
    let data = [...folders]?.filter(f => {
        if (inProjects || ignoreFolderId) {
            const filter = itemsFilter(f, modified, owner, hasPathFilter)
            const hasPathToFolder = getDoesUserHavePathToFolder(f, foldersById)
            return (ignoreFolderId || !f?.team || !hasPathToFolder) ? filter : null
        }
        return f?.[key] === folderId
    })

    return data;
}

export const useGetItems = (() => {
    const folders = useGetFoldersData()
    const files = useGetFilesData()
    return [...folders, ...files]
})

export const useGetNewFolderModal = () => {
    return useSelector(state => state.folders.ui.NewFolderModal)
}

export const useGetUi = () => {
    return useSelector(state => state.ui)
}

export const useGetFiles = () => {
    return useSelector(state => state.files)
}

export const useGetFilesData = (opts) => {
    const { forRecent, ignoreFolderId } = opts || {}
    const data = useGetFiles()?.data
    return useGetSortedByData({ data, forRecent, ignoreFolderId })
}

// this is the one that returns 5 item
export const useGetFilesDataByFolder = ({ folderId, forRecent, ignoreFolderId, collection } = {}) => {
    const files = useGetFilesData({ forRecent, ignoreFolderId })
    const folderIdFromPath = useGetFolderIdFromPath()
    folderId = ignoreFolderId ? null : (folderId || folderIdFromPath)
    // if no folder id is supplied, we will return all filtered files
    const docs = !collection ? files : [...files]?.filter(f => f.collection === collection)
    // modified filters
    const projectsSortFilterMod = useGetProjectsSortFilter('modified')
    const modified = ignoreFolderId ? "" : projectsSortFilterMod
    // owner filter
    const projectsSortFilterOwn = useGetProjectsSortFilter('owner')
    const owner = ignoreFolderId ? "" : projectsSortFilterOwn

    const hasPathFilter = useGetProjectsPathFilter()
    // return data
    // ignoreFolderId still adheres to pathFilters, but NOT modified/owner
    const data = [...docs]?.filter(d => ((ignoreFolderId && !forRecent) || !folderId) ? itemsFilter(d, modified, owner, hasPathFilter) : d.team === folderId)
    return forRecent ? take(data, 5) : data
}

// this is only used for all folders/files as sorting is used across projects/folders
const useGetSortedByData = ({ data, forRecent, ignoreFolderId }) => {
    const inTrash = getInTrash()
    // sort by name if ignoreFolderId, sort by updatedAt if for recent
    const sortByKeys = useGetSortByKeys()
    const keys = !forRecent ? (ignoreFolderId ? ['name', 'asc'] : sortByKeys) : ['updatedAt', 'desc']
    const filtered = data?.filter(d => { if (inTrash === d.archived) return d }) || []
    return useMemo(() => orderBy(filtered, [filtered => keys[0] === 'name' ? filtered?.name?.toLowerCase() : filtered?.[keys[0]]], keys[1]), [filtered])
}

const useGetSortByKeys = () => {
    const sortBy = useGetProjectsSortFilter('sortBy')
    if (sortBy.includes('est')) return ['updatedAt', sortBy === 'newest' ? 'desc' : 'asc']
    if (sortBy.includes('-')) return ['name', sortBy === 'a-z' ? 'asc' : 'desc']
}

const itemsFilter = (i, modified, owner, hasPathFilter) => {
    const inTrash = getInTrash(); if (inTrash) return i;
    // not in trash
    const inModifiedFilter = getModifiedFilter(i, modified)
    const inOwnerFilter = getOwnerFilter(i, owner, hasPathFilter)
    return (inModifiedFilter && inOwnerFilter) ? i : null;
}

const getOwnerFilter = (i, owner, hasPathFilter) => {
    const userId = getUserId()
    const hasOwnFilter = hasPathFilter === 'your-projects'
    const hasSharedFilter = hasPathFilter === 'shared-with-you'
    // user is owner
    if (hasOwnFilter || owner === userId) return i.createdBy === userId ? i : null
    // shared
    if (hasSharedFilter || owner === 'shared') {
        const isCollaborator = i.collaborator
        const isFolderSharedWithUser = getIsPublicFolderOrSharedWithUser(i, { excludeOwner: true })
        if (i?.id === "uxjB27yRzWUjFBl9YJBh") console.log({ isFolderSharedWithUser })
        return (isCollaborator || isFolderSharedWithUser) ? i : null
    }
    // specified owner
    if (owner) return i.createdBy === owner ? i : null
    return i;
}

const getModifiedFilter = (i, modified) => {
    const { timestamp } = modified
    const now = new Date().getTime() || {}
    const after = timestamp?.[0]
    const before = timestamp?.[1] || now;
    const updatedAt = i?.updatedAt?.getTime()
    return (!timestamp || (timestamp && inRange(updatedAt, after, before))) ? i : null
}

export const useIsLoadingEditor = () => {
    return !useHasEditorLoaded()
}

export const useHasEditorLoaded = () => {
    return useGetEditor()?.loaded
}

export const useIsLoadingSitemap = () => {
    const inSitemap = useInSitemap()
    const sitemap = useGetSitemap()
    return inSitemap && !sitemap?.loaded
}

export const useIsLoadingUserFlow = () => {
    const inUserFlow = useInUserFlow();
    const flow = useGetUserFlow();
    const sitemap = useGetSitemap();
    const isLoadingUserFlow = inUserFlow && !flow.loaded
    const isLoadingSitemap = (flow?.sitemap && !sitemap?.loaded || false)
    return isLoadingUserFlow || isLoadingSitemap;
}

export const useIsLinkedUserFlowPage = (node) => {
    return getIsLinkedUserFlowPage(node)
}

export const useIsUserFlowLinkedToSitemap = () => {
    return getIsUserFlowLinkedToSitemap()
}

export const useIsRetrievingRevisionHistoryDrawerChanges = () => {
    const RevisionHistoryDrawer = useGetRevisionHistoryDrawer();
    const { showing, retrieved, retrieving } = RevisionHistoryDrawer;
    return showing && (retrieving || !retrieved);
}

export const useShowEditorLoadingSpinner = () => {
    const isLoadingEditor = useIsLoadingEditor()
    const isLoadingSitemap = useIsLoadingSitemap()
    const isLoadingUserFlow = useIsLoadingUserFlow()
    const inUserFlow = useInUserFlow()
    const isRetrievingRevisionHistoryDrawerChanges = useIsRetrievingRevisionHistoryDrawerChanges()
    const isEditorDocAvailable = useIsEditorDocAvailable()
    const isTemplateDrawerOpen = getIsTemplateDrawerOpen()
    if (isTemplateDrawerOpen) return false;
    if (!isEditorDocAvailable) return false;
    if (isLoadingEditor || !inUserFlow && isLoadingSitemap || isLoadingUserFlow || isRetrievingRevisionHistoryDrawerChanges) {
        return true
    }
}

export const useHitSitemapsLimit = () => {
    return useHasFreeUserCreatedFile('sitemaps') || false
}

export const useGetSitemapsCount = (key) => {
    const team = useGetTeamDataFromFirestoreListener();
    return team?.sitemaps?.[key]?.length || 0;
}

export const useGetTeams = () => {
    return useSelector(state => state.teams);
}

export const useGetFirestoreListener = () => {
    return useSelector(state => state.firestore);
}

export const useGetTeamDataFromFirestoreListener = () => {
    const firestore = useGetFirestoreListener();
    const user = useGetUser();
    const id = user.token?.claims?.team || user.uid
    const team = firestore.data?.teams?.[id];
    return team ? { id, ...team } : undefined;
}

export const useCanUpgradePlan = () => {
    const planId = useGetShortPlanId();
    const inOrganization = useInOrganization();
    const isAdmin = useIsOrganizationAdmin();
    const canEdit = !inOrganization || isAdmin;
    const canUpgrade = canEdit && planId !== 'agency';
    return canUpgrade;
}

export const useGetArrayOfUsers = (key, filter) => {
    let users = useGetUsers();
    const user = useGetUser();
    // users['owen@owen.com'] = { email: 'owen@owen.com', role: 'user', invited: true }
    const userData = convertObjToArrayWithKey(users)
    sortArray(userData, { by: ['role', 'invited'], order: ['asc', 'invited'] }) // sorts by role and then invited at the bottom
    userData.some((u, idx) => u.id == user.id && userData.unshift(userData.splice(idx, 1)[0])) // ensures current user is always at the top
    return userData.filter(user => { return !key ? true : (filter === false && !user[key] || user[key] === filter) });
}

export const useIsSameUser = (userId) => {
    const user = useGetUser();
    return user?.id === userId || user?.email === userId;
}

export const useRenderActiveSitemapsCountBadge = () => {
    const inOrganization = useInOrganization();
    const isInProPlan = useInProPlan();
    const sitemapsCount = useGetSitemapsCount('active')
    if (!inOrganization || isInProPlan) return `${sitemapsCount} of ${!inOrganization ? 1 : 5}`;
    return sitemapsCount;
};

export const useGetAuth = () => {
    return useSelector(state => state.auth);
}

export const useGetUpgradeModal = () => {
    return useSelector(state => state.ui.UpgradeModal);
}

export const useGetInvitationsDoc = () => {
    const firestore = useGetFirestoreListener();
    const { invitations } = firestore.data;
    return invitations?.[keys(invitations)[0]];
}

export const useHasPendingInvitations = () => {
    const invitationsDoc = useGetInvitationsDoc();
    const { sitemaps, organizations } = invitationsDoc || {};
    return !isEmpty(sitemaps) || !isEmpty(organizations);
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

// not a hook
export const SetTitle = (title) => {
    useEffect(() => {
        if (title !== document.title) document.title = `${title} - Rarchy`
    })
}

export const useIsTextOverflown = (ref) => {

    const [isOverflown, setIsOverflown] = useState(false);

    const compareSize = () => {
        const element = ref.current;
        const compare = element?.offsetWidth < element?.scrollWidth || element?.offsetHeight < element?.scrollHeight;
        setIsOverflown(compare);
    };

    useEffect(() => {
        compareSize();
    }, []);

    return isOverflown;
}

export const useGetPhotoURL = (user) => getPhotoURL(user)

export const useGetAccount = (account) => {
    // if no account, assume it's for current user
    const user = useGetUser()
    if (!account) return (user?.firstName ? `${user?.firstName?.[0]} ${user.lastName?.[0]}` : user?.email)?.toLowerCase();
    return account?.name
}

export const useIsFolderStarred = (folderId) => {
    const user = useGetUser()
    return user?.starred?.folders?.includes(folderId)
}

export const useIsSitemapImportPending = () => {
    return useGetSitemaps('processing')?.pending
}

export const useGetImportingSitemap = () => {
    const firestore = useGetFirestoreListener()
    // return exampleSitemapProcessing
    let processing = null;
    if (firestore && firestore.ordered && !isEmpty(firestore.ordered.sitemaps_processing)) processing = { ...firestore.ordered.sitemaps_processing[0] };
    // set domain
    /* if (processing) {
        if (!processing.domain && processing.url) processing.domain = getDomainFromURL(processing.url)
    } */
    // return
    return processing;
}

export const useIsImportingSitemap = () => {
    const isImporting = !isEmpty(useGetImportingSitemap())
    // const hasProcessed = useHasProcessedSitemapImport()
    return isImporting // && !hasProcessed
}

export const useIsImportingSitemapById = (id) => {
    return (useGetImportingSitemap() || {})?.id === id
}

export const useHasProcessedSitemapImport = () => {
    return useGetImportingSitemap()?.processed
}

export const useIsImportingIntoExistingSitemap = () => {
    return useGetImportingSitemap?.sitemapId
}

// CAN create/share a public folder within a private folder
// need to rejig projects UI to ensure that any public folder within a private folder is shown at the top level (like canva)
export const useIsInPrivateFolder = (folder) => {
    const folders = useGetFolders()?.byId
    let inPrivate = false
    function recurse(f) {
        const parent = folders?.[f?.team];
        if (!isEmpty(parent?.members)) { inPrivate = true; return; }
        if (parent) recurse(parent)
    }
    recurse(folder)
    return inPrivate
}

// CAN'T create/share a private folder within a public folder
export const useIsInPublicFolder = (folder) => {
    const folders = useGetFolders()?.byId
    let inPublic = false
    function recurse(folder) {
        const parent = folders?.[folder?.team];
        if (parent?.members === null) { inPublic = true; return; }
        if (parent) recurse(parent)
    }
    recurse(folder)
    return inPublic
}

export const useGetAllMembersOfFolderHierarchy = (folder) => {
    const folders = useGetFolders()?.byId
    let members = [];
    function recurse(f) {
        members = [f?.createdBy, ...members, ...(f?.members || [])]
        const parent = folders?.[f?.team];
        if (parent) recurse(parent)
    }
    recurse(folder)
    return compact(uniq(members))
}

export const useGetShareFileModal = () => {
    return useGetFiles()?.ui?.ShareFileModal
}

export const useGetAddUsersModal = () => {
    return useGetUi()?.AddUsersModal
}

export const useGetFileFromFiles = (fileId) => {
    return useGetFilesData()?.find(f => f?.id === fileId)
}

export const useIsLoggedIn = () => {
    const { isLoaded, isEmpty } = useGetFirebaseFromState()?.auth
    if (isLoaded && !isEmpty) return true;
}

export const useIsLoggedInAndGotUser = () => {
    const userId = useGetUserId()
    const isLoggedIn = useIsLoggedIn()
    if (userId && isLoggedIn) return true;
}