import { store } from '../../../store';
import { keys } from 'lodash'

export const getCanFullyEditFile = (doc) => {
    return doc?.member || getLatestEditLevel(doc) === 'full';
}

export const getCanEditInEditor = (doc) => {
    const editLevel = getLatestEditLevel(doc);
    return getCanFullyEditFile(doc) || editLevel === 'edit';
}

export const getCanCommentInEditor = (doc) => {
    return getLatestEditLevel(doc) ? true : false;
}

export const getLatestEditLevel = (doc) => {

    const { user, editor, folders } = store.getState()

    if (user?.token) {

        const { organization, user_id } = user?.token?.claims || {}
        const accountId = organization || user_id
        const toCheck = doc || editor

        // user in personal sitemap
        if (!user?.token?.claims?.organization && user?.token?.claims?.user_id === toCheck?.id) return 'full'
        // member of sitemaps team
        if ([...keys(folders.byId), accountId, user?.token?.claims?.team].includes(toCheck?.team)) return 'full'
        // collaborator
        return toCheck?.collaborators?.[user?.id]?.access;
        // unknown user is viewing sitemap - NO ACCESS - NO EDITLEVEL
    }

}