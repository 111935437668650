import { getFirebase } from "react-redux-firebase";

import model from './model';

export default (state = model.user, action) => {
    switch (action.type) {
        case 'SET_STARRED_PENDING': {
            return {
                ...state,
                starred: action.meta.starred,
            };
        }
        // case 'INIT_ACCOUNT_FULFILLED':
        case 'SWITCH_ACCOUNT_FULFILLED':
        /* eslint-disable-next-line */
        case 'SWITCH_TEAM_FULFILLED':
        /* eslint-disable-next-line */
        case 'REFRESHED_TOKEN': {
            return { ...state, token: action.payload };
        }
        case '@@reactReduxFirebase/AUTHENTICATION_INIT_STARTED': {
            return state
        }
        case '@@reactReduxFirebase/AUTHENTICATION_INIT_FINISHED': {
            return state
        }
        case '@@reactReduxFirebase/SET_PROFILE': {
            const user = getFirebase().auth().currentUser;
            // merge into new user object
            return { ...state, id: user.uid, ...action.profile, }
        }
        default:
            return state;
    }
};
