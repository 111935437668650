import {
    Box,
    Button,
    Center,
    DarkMode,
    SimpleGrid,
    Stack,
    Text,
} from "@chakra-ui/react"

import { links } from "./_data"
import Link from "next/link"
import { Logo } from "../../../../src/screens/Auth/SignupLogin/Components/Logo"
import { Headline } from "../Headline"
import { FooterFAQS } from "../../-sections/FAQs/Footer"
import { Container } from "../Container"

export const Footer = ({ bgColor }) => (
    <DarkMode>
        <Box bgColor={bgColor || "white"}>
            <Box borderTopRadius="56px" bg="blue.600">
                <Box
                    as='section'
                    position='relative'
                    py={12}
                    pt={24}
                // backgroundImage='linear-gradient(to right, blue.600, blue.500)'
                >
                    <Container>
                        <Stack
                            maxWidth={{ base: 'full', md: '8xl' }}
                            direction={{ base: 'column', lg: 'row' }}
                            spacing={{ base: '6', lg: '36' }}
                        >
                            <Stack spacing='10' color="fg.default">
                                <Headline
                                    heading='Got any questions?'
                                    textAlign="left"
                                /* subheading={
                                    <Stack spacing={3} color="whiteAlpha.900" fontSize="lg">
                                        <Box>
                                            From agencies to internal teams, Rarchy's powerful sitemap and user flow tools are streamlining website planning for companies all over the world. </Box>
                                        <Box>But don’t just take our word for it – here’s what some of our customers have to say.</Box>
                                    </Stack>
                                } */
                                />
                            </Stack>
                            <Box w="full">
                                <FooterFAQS />
                            </Box>
                        </Stack>
                    </Container>
                </Box>
                <Container as="footer">
                    <Stack
                        justify="space-between"
                        align="start"
                        direction={{
                            base: "column",
                            lg: "row",
                        }}
                        py={{
                            base: "12",
                            md: "16",
                        }}
                        spacing="8"
                    >
                        <Stack
                            spacing={{
                                base: "6",
                                md: "8",
                            }}
                            align="start"
                        >
                            <Link href="/" aria-label="Logo"><Logo /></Link>
                            <Text color="fg.default" maxW="sm">Plan and collaborate on website structures and customer journeys, with Rarchy's visual sitemap generator and website planning tools.</Text>
                        </Stack>
                        <SimpleGrid
                            columns={{
                                base: 2,
                                md: 4,
                            }}
                            gap="8"
                            width={{
                                base: "full",
                                lg: "auto",
                            }}
                        >
                            {links?.map((group, idx) => (
                                <Stack
                                    key={idx}
                                    spacing="4"
                                    minW={{
                                        lg: "40",
                                    }}
                                >
                                    <Text fontSize="md" fontWeight="medium" color="fg.default">
                                        {group.title}
                                    </Text>
                                    <Stack spacing="3" shouldWrapChildren>
                                        {group.links?.map((link, idx) => (
                                            <Button key={idx} href={link.href} as={Link} size="lg" variant="text" color="fg.default" colorScheme="blue">
                                                {link.label}
                                            </Button>
                                        ))}
                                    </Stack>
                                </Stack>
                            ))}
                        </SimpleGrid>
                    </Stack>
                    <Stack
                        pt="8"
                        pb="12"
                        justify="space-between"
                        direction={{
                            base: "column-reverse",
                            md: "row",
                        }}
                        align="left"
                    >
                        <Text fontSize="sm" color="fg.default">
                            &copy; {new Date().getFullYear()} Rarchy PTY LTD. All rights reserved.
                        </Text>
                        {/* <ButtonGroup variant="tertiary">
                        <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin />} />
                        <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub />} />
                        <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter />} />
                    </ButtonGroup> 
                */}
                    </Stack>
                </Container>
            </Box>
        </Box>
    </DarkMode>
)