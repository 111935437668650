import model from './model';
import { pickBy, identity } from 'lodash';

export default (state = model.sitemaps, action) => {
  switch (action.type) {
    /**** PROCESSING ****/
    case 'PROCESS_NEW_SITEMAP_FULFILLED': {
      const { sitemap } = action.payload;
      return {
        ...state,
        processing: {
          ...state.processing,
          pending: false,
          type: sitemap?.type,
          [sitemap?.type]: {
            ...state.processing[sitemap?.type],
            update: sitemap?.update
          },
        },
      };
    }
    case 'PROCESS_NEW_SITEMAP_PENDING': {
      const { sitemap } = action.meta;
      return {
        ...state,
        processing: {
          ...state.processing,
          pending: true,
          [sitemap?.type]: sitemap,
        },
      };
    }
    case 'PROCESS_NEW_SITEMAP_REJECTED': {
      const { sitemap } = action.meta;
      return {
        ...state,
        processing: {
          ...state.processing,
          pending: false,
          [sitemap?.type]: {
            ...sitemap,
            error: action.payload.message,
            processed: new Date().getTime()
          },
        },
      };
    }

    case 'GET_IMPORTED_SITEMAPS_PENDING': {
      return {
        ...state,
        imported: {
          ...state.imported,
          loading: true,
          loaded: false
        },
      };
    }

    case 'GET_IMPORTED_SITEMAPS_FULFILLED': {
      return {
        ...state,
        imported: {
          ...state.imported,
          byId: {
            ...state.imported.byId,
            ...action.payload?.byId
          },
          loading: false,
          loaded: true
        },
      };
    }

    case 'MERGE_IMPORTED_SITEMAP_BY_ID': {
      return {
        ...state,
        imported: {
          ...state.imported,
          byId: pickBy({
            ...state.imported.byId,
            ...action.data
          }, identity)
        },
      };
    }

    case 'GET_IMPORTED_SITEMAP_PAGES_PENDING': {
      return {
        ...state,
        imported: {
          ...state.imported,
          byId: {
            ...state.imported.byId,
            ...action.meta
          },
        },
      };
    }

    case 'GET_IMPORTED_SITEMAP_PAGES_FULFILLED': {
      return {
        ...state,
        imported: {
          ...state.imported,
          byId: {
            ...action.payload?.byId
          },
        },
      };
    }

    /*** PROCESS PROGRESS DATA ***/
    case 'GET_IMPORTING_SITEMAP_PROGRESS_DATA_FULFILLED': {
      return {
        ...state,
        processing: {
          ...state.processing,
          progress: {
            ...state.processing.progress,
            loading: false,
            data: action.payload.data
          }
        },
      };
    }
    /*** PROCESS PROGRESS DATA ***/

    case 'CLEAR_PROCESSED_SITEMAP_PENDING': {
      return {
        ...state,
        processing: {
          ...state.processing,
          [state.processing.type]: {
            ...model.sitemaps.processing[state.processing.type],
          },
          clearing: true
        },
      };
    }

    case 'CLEAR_PROCESSED_SITEMAP_FULFILLED':
    case 'CLEAR_PROCESSED_SITEMAP_REJECTED': {
      return {
        ...state,
        processing: {
          ...state.processing,
          [state.processing.type]: {
            ...model.sitemaps.processing[state.processing.type],
          },
        },
      };
    }

    /*** SWITCHING ***/
    case 'SWITCH_ACCOUNT_PENDING':
    case 'SWITCH_ACCOUNT_FULFILLED': {
      return { ...model.sitemaps };
    }
    /*** SWITCHING ***/

    default:
      return state;
  }
};
