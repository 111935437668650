import { cloneDeep, isEmpty } from 'lodash';

import deepmerge from 'deepmerge';
import model from './model';

const sitemap = (state = { ...model.sitemap }, action) => {

  switch (action.type) {

    case 'TOGGLE_PAGE_BUTTONS': {
      return {
        ...state,
        ui: {
          ...state.ui,
          PageButtons: {
            ...state.ui.PageButtons,
            showing: action.showing,
            node: !action.showing ? null : action.node ? action.node : state.ui.PageButtons.node,
            zooming: action.zooming ? true : false,
            renaming: action.renaming ? true : false
          }
        }
      };
    }

    case 'INIT_SITEMAP_PENDING': {
      return {
        ...state,
        ...action.meta,
        loading: true,
        loaded: false,
        // never put loading: true here
      };
    }

    case 'INIT_SITEMAP_FULFILLED': {
      return {
        ...state,
        ...action.payload
        // never put loading: true here
      };
    }

    case 'INIT_SITEMAP_REJECTED': {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        loaded: true
      };
    }

    case 'INIT_SITEMAP_DOC_FULFILLED': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'INIT_SITEMAP_DOC_PENDING': {
      return {
        ...state,
        ...action.meta,
        loading: true
      };
    }
    case 'INIT_SITEMAP_DOC_REJECTED': {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.message
      };
    }

    case 'INIT_SITEMAP_PAGES_DOC_FULFILLED': {
      const { doc, ...rest } = action.payload; // don't include 'doc' when spreading
      return {
        ...state,
        data: { ...state.data, ...rest.data },
        docs: { ...state.docs, ...rest.docs },
        loaded: true // (test) // (state.id === 'new' || state.id === 'generate') ? true : false // ensures that /sitemap/new loads as quickly as possible (sitemap usually loads on covers loaded)
      };
    }

    case 'INIT_SITEMAP_WEBSITE_SECTIONS_DOC_FULFILLED': {
      return {
        ...state,
        docs: { ...state?.docs, ...action?.payload?.docs }
      };
    }

    case 'INIT_SITEMAP_SECTIONS_DOC_FULFILLED': {
      return {
        ...state,
        data: { ...state.data, page_sections: action.payload.data.sections },
        docs: { ...state.docs, ...action.payload.docs }
      };
    }

    case 'INIT_SITEMAP_SEO_DOC_FULFILLED': {
      return {
        ...state,
        data: { ...state.data, seo: action.payload.pages },
      };
    }

    case 'INIT_SITEMAP_SCHEDULED_SCREENSHOTS_DOC_FULFILLED': {
      return {
        ...state,
        docs: { ...state.docs, scheduled_screenshots: action.payload }
      };
    }

    // so we can treeshake and not load in marketing
    case 'INIT_SITEMAP_COVERS_COLLECTIONS': {
      return {
        ...state,
        covers: { ...state.covers, collections: action.collections }
      }
    }

    /*** init sitemap covers ***/
    case 'INIT_SITEMAP_COVERS_DOC_FULFILLED': {
      return {
        ...state,
        covers: { ...state.covers, ...action.payload, collections: state.covers.collections }
      }
    }
    case 'INIT_SITEMAP_COVERS_DOC_PENDING': {
      return { ...state, covers: { ...model.sitemap?.covers, collections: state.covers.collections, device: action.meta.device || model?.sitemap?.covers?.device } };
    }
    case 'INIT_SITEMAP_COVERS_DOC_REJECTED': {
      return { ...state, covers: { ...model.sitemap?.covers, collections: state.covers.collections, error: action.payload.code } };
    }
    case 'IMPORT_SITEMAP_COVERS_COLLECTIONS': {
      return { ...state, covers: { ...state.covers, collections: action.collections } };
    }
    /*** init sitemap covers ***/

    /*** init sitemap comments ***/
    case 'INIT_SITEMAP_COMMENTS_DOC_FULFILLED': {
      return { ...state, comments: action.payload.comments, members: action.payload.members /* used for new mentions + comment user data */ };
    }
    case 'INIT_SITEMAP_COMMENTS_DOC_PENDING': {
      return { ...state, comments: action.meta.comments }
    }
    case 'INIT_SITEMAP_COMMENTS_DOC_REJECTED': {
      return { ...state, comments: model.sitemap?.comments, error: action.payload.code };
    }
    /*** init sitemap comments ***/

    /*** init sitemap covers ***/
    case 'INIT_SITEMAP_COVERS_FULFILLED': {
      return {
        ...state,
        covers: deepmerge(model.sitemap?.covers, action.payload)
      }
    }
    case 'INIT_SITEMAP_COVERS_PENDING': {
      return { ...state, covers: model.sitemap?.covers };
    }
    case 'INIT_SITEMAP_COVERS_REJECTED': {
      return { ...state, covers: model.sitemap?.covers, error: action.payload.code };
    }
    /*** init sitemap covers ***/

    case 'SET_ROOT': {
      return { ...state, data: { ...state.data, root: action.root } };
    }
    case 'INIT_SECTION': {
      return {
        ...state,
        data: { ...state.data, section: action.section.id === 'home' ? undefined : action.section },
        history: {
          undo: [],
          redo: [],
        },
      };
    }
    case 'SET_SECTION': {
      return {
        ...state,
        data: { ...state.data, section: action.section },
        ui: {
          ...state.ui,
          SubfoldersTabs: {
            ...state.ui.SubfoldersTabs, [action.section.id]: { name: action.section.name }
          }
        }
      };
    }
    case 'ADD_SUBFOLDER_TO_TABS': {
      return {
        ...state,
        ui: {
          ...state.ui,
          SubfoldersTabs: {
            ...state.ui.SubfoldersTabs, [action.subfolderToAdd.id]: { name: action.subfolderToAdd.name }
          }
        }
      };
    }
    case 'REMOVE_SUBFOLDERS_FROM_TABS': {
      return {
        ...state,
        ui: {
          ...state.ui,
          SubfoldersTabs: action.subfolders
        }
      };
    }
    case 'UNSET_SECTION': {
      return {
        ...state,
        data: { ...state.data, section: undefined },
        ui: {
          ...state.ui,
          SubfoldersTabs: {}
        },
        history: {
          undo: [],
          redo: [],
        },
      };
    }
    case 'SET_WEBSITE_SECTIONS': {
      return {
        ...state,
        data: { ...state.data, website_sections: action.sections }
      };
    }
    case 'SET_NODES_AND_LINKS': {
      return {
        ...state,
        data: { ...state.data, nodes: action.nodes, links: action.links }
      };
    }
    case 'SET_NODE_HEIGHTS': {
      return {
        ...state,
        data: { ...state.data, heights: { ...state.data.heights, ...action.heights } }
      };
    }
    case 'RESET_NODE_HEIGHTS': {
      const heights = { ...state.data.heights }; action.nodeIds.forEach(id => heights[id] = {});
      return {
        ...state,
        data: { ...state.data, heights }
      };
    }
    case 'SET_FORMAT': {
      return { ...state, format: action.format, data: { ...state.data, root: cloneDeep(state.data.root) } };
    }
    case 'UNDO_USER_CHANGE': {
      return {
        ...state,
        history: {
          undo: action.undo,
          redo: action.redo,
        },
      };
    }
    case 'REDO_USER_CHANGE': {
      return {
        ...state,
        history: {
          undo: action.undo,
          redo: action.redo,
        },
      };
    }

    case 'TOGGLE_PAGE_DRAWER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          PageDrawer: {
            ...state.ui.PageDrawer,
            showing: action.showing,
            editPageUrl: action.editPageUrl,
            defaultIndex: action.defaultIndex,
            page: action.page,
            url: action.url
          },
        },
      };
    }

    case 'TOGGLE_CAPTURE_DRAWER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          CaptureDrawer: {
            ...state.ui.CaptureDrawer,
            showing: action.showing,
          },
        },
      };
    }

    case 'TOGGLE_SCHEDULED_SCREENSHOTS_DRAWER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          RecurringScreenshotsDrawer: {
            ...state.ui.RecurringScreenshotsDrawer,
            showing: action.showing,
            page: action.page
          },
        },
      };
    }

    case 'SHOW_SELECT_COVER_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          SelectCoverModal: {
            ...state.ui.SelectCoverModal,
            showing: { page: action.page, url: action.url, tab: action.tab }
          },
        },
      };
    }
    case 'CAPTURE_COVER_FROM_WEBSITE_FULFILLED': {
      return {
        ...state,
        covers: isEmpty(action.payload) ? state.covers :
          {
            ...state.covers,
            pages: { ...state.covers.pages, ...action.payload.pages }
          }
      }
    }
    case 'CAPTURE_COVER_FROM_WEBSITE_PENDING': {
      return {
        ...state,
        ui: {
          ...state.ui,
          SelectCoverModal: {
            showing: false
          }
        }
      }
    }
    case 'CAPTURE_COVER_FROM_WEBSITE_REJECTED': {
      return {
        ...state,
        ui: {
          ...state.ui,
        }
      }
    }
    case 'CLEAR_PREVIEWED_SCREENSHOT_PAGE': {
      return {
        ...state,
        covers: {
          ...state.covers,
          pages: { ...state.covers.pages, ...action.pages }
        }
      }
    }
    case 'CHANGE_COVER_MODAL_TAB': {
      return {
        ...state,
        ui: {
          ...state.ui,
          SelectCoverModal: {
            ...state.ui.SelectCoverModal,
            showing: {
              ...state.ui.SelectCoverModal.showing,
              tab: action.tab,
            }
          },
        },
      };
    }

    case 'HIDE_SELECT_COVER_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          SelectCoverModal: {
            ...state.ui.SelectCoverModal,
            page: undefined,
            showing: false,
          },
        },
      };
    }
    /*** REPLY COMMENT ***/
    case 'REPLY_TO_PAGE_COMMENT_FULFILLED': {
      return {
        ...state,
      };
    }
    case 'REPLY_TO_PAGE_COMMENT_PENDING': {
      return {
        ...state,
        comments: action.meta.comments,
      };
    }
    case 'REPLY_TO_PAGE_COMMENT_REJECTED': {
      return {
        ...state,
      };
    }
    /*** REPLY COMMENT ***/
    /*** EDIT COMMENT ***/
    case 'EDIT_PAGE_COMMENT_FULFILLED': {
      return {
        ...state,
      };
    }
    case 'EDIT_PAGE_COMMENT_PENDING': {
      return {
        ...state,
        comments: action.meta.comments,
      };
    }
    case 'EDIT_PAGE_COMMENT_REJECTED': {
      return {
        ...state,
      };
    }
    /*** REPLY COMMENT ***/
    case 'TOGGLE_PAGE_COMMENTS_DRAWER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          PageCommentsDrawer: {
            showing: action.showing,
          }
        },
      };
    }
    case 'SHOW_PAGE_COMMENTS_POPOVER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          PageCommentsPopover: {
            page: action.page,
            node: action.node,
            offset: action.offset,
            showing: true,
          }
        },
      };
    }
    case 'HIDE_PAGE_COMMENTS_POPOVER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          PageCommentsPopover: {
            page: undefined,
            node: undefined,
            offset: [],
            showing: false,
          },
        },
      };
    }
    case 'DELETE_PAGE_COMMENT_THREAD_FULFILLED': {
      return {
        ...state,
      };
    }
    case 'DELETE_PAGE_COMMENT_THREAD_PENDING': {
      return {
        ...state,
        comments: action.meta.comments,
        ui: {
          ...state.ui,
          PageCommentsPopover: {
            page: undefined,
            node: undefined,
            offset: [],
            showing: false,
          },
        },
      };
    }
    case 'DELETE_PAGE_COMMENT_THREAD_REJECTED': {
      return {
        ...state,
      };
    }
    case 'DELETE_PAGE_COMMENT_FULFILLED': {
      return {
        ...state,
      };
    }
    case 'DELETE_PAGE_COMMENT_PENDING': {
      return {
        ...state,
        comments: action.meta.comments,
      };
    }
    case 'DELETE_PAGE_COMMENT_REJECTED': {
      return {
        ...state,
      };
    }
    case 'SHOW_COVERS': {
      return {
        ...state,
        showCovers: true,
      };
    }
    case 'HIDE_COVERS': {
      return {
        ...state,
        showCovers: false,
      };
    }
    case 'SAVE_COVER_CHANGE_FULFILLED': {
      return { ...state };
    }
    case 'SAVE_COVER_CHANGE_PENDING': {
      const { selected, page, type, setDefault, uid } = action.meta;
      return {
        ...state,
        covers: {
          ...state.covers,
          pages: {
            ...state.covers.pages,
            [page]: {
              ...state.covers.pages[page],
              [type]: selected,
            },
          },
          'default': setDefault ? selected : state.covers.pages.default,
          lastEdit: uid
        },
      };
    }
    case 'SAVE_COVER_CHANGE_REJECTED': {
      return {
        ...state,
      };
    }
    case 'SWITCH_COVERS_DEVICE': {
      return {
        ...state,
        covers: { ...state.covers, device: action.device },
      };
    }
    case 'SHOW_SHARE_SITEMAP_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          ShareModal: {
            ...state.ui.ShareModal, showing: true
          }
        }
      };
    }
    case 'HIDE_SHARE_SITEMAP_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          ShareModal: {
            showing: false,
            ...model.sitemap?.ui.ShareModal
          }
        }
      };
    }
    case 'SHOW_OVERFLOW_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          OverflowModal: {
            showing: true,
            parent: action.parent,
            pages: action.pages
          }
        }
      };
    }
    case 'HIDE_OVERFLOW_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          OverflowModal: {
            showing: false,
            ...model.sitemap?.ui.OverflowModal
          }
        }
      };
    }
    case 'SHOW_FULLSCREEN_COVER_DRAWER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          FullscreenCoverDrawer: {
            showing: true,
            page: action.page,
            preview: action.preview,
            device: action.device
          }
        }
      };
    }
    case 'HIDE_FULLSCREEN_COVER_DRAWER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          FullscreenCoverDrawer: { showing: false }
        }
      };
    }
    case 'TOGGLE_EXPORT_PDF_DRAWER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          ExportPDFDrawer: { showing: action.showing }
        }
      };
    }

    /*** AUTO-SAVE ***/
    case 'ADD_PAGE_CHANGE': {
      const undo = cloneDeep(state.history.undo);
      if (action.history) undo.push(action.history);
      return {
        ...state,
        history: {
          undo: undo,
          redo: action.history ? [] : state.history.redo,
        },
        changes: {
          ...state.changes,
          pages: {
            ...state.changes.pages, data: [...state.changes.pages.data, action.change]
          }
        },
        ui: {
          ...state.ui,
          PageDrawer: {
            ...action.PageDrawer ? action.PageDrawer : state.ui.PageDrawer
          },
        },
      };
    }
    case 'ADD_WEBSITE_SECTION_CHANGE': {
      const undo = cloneDeep(state.history.undo);
      if (action.history) undo.push(action.history);
      return {
        ...state,
        history: {
          undo: undo,
          redo: action.history ? [] : state.history.redo,
        },
        changes: {
          ...state.changes,
          website_sections: {
            ...state.changes.website_sections, data: [...state.changes.website_sections.data, action.change]
          }
        },
        ui: {
          ...state.ui,
          PageDrawer: {
            ...action.PageDrawer ? action.PageDrawer : state.ui.PageDrawer
          },
        },
      };
    }
    case 'ADD_PAGE_SECTION_CHANGE': {
      const undo = cloneDeep(state.history.undo);
      if (action.history) undo.push(action.history);
      return {
        ...state,
        history: {
          undo: undo,
          redo: action.history ? [] : state.history.redo,
        },
        changes: {
          ...state.changes,
          sections: {
            ...state.changes.sections,
            data: [...state.changes.sections.data, action.change]
          }
        }
      };
    }
    case 'ADD_COVERS_CHANGE': {
      return {
        ...state,
        changes: {
          ...state.changes,
          covers: {
            ...state.changes.covers, data: [...state.changes.covers.data, action.change]
          }
        },
        covers: {
          ...state.covers,
          pages: deepmerge(state.covers.pages, action.covers),
          lastEdit: action.state.user.id
        }
      }
    }
    case 'ADD_COMMENTS_CHANGE': {
      return {
        ...state,
        changes: {
          ...state.changes,
          comments: {
            ...state.changes.comments, data: [...state.changes.comments.data, action.change]
          }
        },
        comments: { ...state.comments, ...action.comment }
      };
    }
    /*** SAVE PAGE CHANGES ***/
    case 'SAVE_PAGE_CHANGES_FULFILLED': {
      return {
        ...state,
        docs: {
          ...state.docs,
        },
        changes: {
          ...state.changes,
          pages: {
            ...state.changes.pages,
            saving: false,
            error: null,
            errorCount: 0
          }
        }
      }
    }
    case 'SAVE_PAGE_CHANGES_PENDING': {
      return {
        ...state,
        docs: {
          ...state.docs,
        },
        changes: {
          ...state.changes,
          pages: {
            ...state.changes.pages,
            saving: true,
          }
        }
      }
    }
    case 'SAVE_PAGE_CHANGES_REJECTED': {
      return {
        ...state,
        changes: {
          ...state.changes,
          pages: {
            ...state.changes.pages,
            saving: false,
            error: action.error,
            errorCount: state.changes.pages.errorCount + 1
          }
        }
      }
    }
    /*** SAVE PAGE CHANGES ***/
    /*** SAVE PAGE SECTION CHANGES ***/
    case 'SAVE_PAGE_SECTION_CHANGES_FULFILLED': {
      return {
        ...state,
        changes: {
          ...state.changes,
          sections: {
            ...state.changes.sections,
            saving: false,
            error: null,
            errorCount: 0
          }
        }
      }
    }
    case 'SAVE_PAGE_SECTION_CHANGES_PENDING': {
      return {
        ...state,
        changes: {
          ...state.changes,
          sections: {
            ...state.changes.sections,
            saving: true,
          }
        }
      }
    }
    case 'SAVE_PAGE_SECTION_CHANGES_REJECTED': {
      return {
        ...state,
        changes: {
          ...state.changes,
          sections: {
            ...state.changes.sections,
            saving: false,
            error: action.error,
            errorCount: state.changes.sections.errorCount + 1
          }
        }
      }
    }
    /*** SAVE PAGE SECTION CHANGES ***/
    /*** SAVE COVERS CHANGES ***/
    case 'SAVE_COVERS_CHANGES_FULFILLED': {
      return {
        ...state,
        changes: {
          ...state.changes,
          covers: {
            ...state.changes.covers,
            saving: false,
            error: null,
            errorCount: 0
          }
        }
      }
    }
    case 'SAVE_COVERS_CHANGES_PENDING': {
      return {
        ...state,
        changes: {
          ...state.changes,
          covers: {
            ...state.changes.covers,
            saving: true,
          }
        }
      }
    }
    case 'SAVE_COVERS_CHANGES_REJECTED': {
      return {
        ...state,
        changes: {
          ...state.changes,
          covers: {
            ...state.changes.covers,
            saving: false,
            error: action.error,
            errorCount: state.changes.covers.errorCount + 1
          }
        }
      }
    }
    /*** SAVE COVERS CHANGES ***/
    /*** SAVE COMMENTS CHANGES ***/
    case 'SAVE_COMMENTS_CHANGES_FULFILLED': {
      return {
        ...state,
        changes: {
          ...state.changes,
          comments: {
            ...state.changes.comments,
            saving: false,
            error: null,
            errorCount: 0
          }
        }
      }
    }
    case 'SAVE_COMMENTS_CHANGES_PENDING': {
      return {
        ...state,
        changes: {
          ...state.changes,
          comments: {
            ...state.changes.comments,
            saving: true,
          }
        }
      }
    }
    case 'SAVE_COMMENTS_CHANGES_REJECTED': {
      return {
        ...state,
        changes: {
          ...state.changes,
          comments: {
            ...state.changes.comments,
            saving: false,
            error: action.error,
            errorCount: state.changes.comments.errorCount + 1
          }
        }
      }
    }
    /*** SAVE COMMENTS CHANGES ***/
    case 'CLEAR_SAVED_PAGE_CHANGES': {
      return {
        ...state,
        // returns all changes that weren't just saved
        changes: {
          ...state.changes,
          pages: {
            ...state.changes.pages, data: state.changes.pages.data.filter(
              change => action.savedChanges.indexOf(change.id) < 0
            ),
          }
        }
      };
    }
    case 'CLEAR_SAVED_WEBSITE_SECTION_CHANGES': {
      return {
        ...state,
        // returns all changes that weren't just saved
        changes: {
          ...state.changes,
          website_sections: {
            ...state.changes.website_sections, data: state.changes.website_sections.data.filter(
              change => action.savedChanges.indexOf(change.id) < 0
            ),
          }
        }
      };
    }
    case 'CLEAR_SAVED_PAGE_SECTION_CHANGES': {
      return {
        ...state,
        // returns all changes that weren't just saved
        changes: {
          ...state.changes,
          sections: {
            ...state.changes.sections, data: state.changes.sections.data.filter(
              change => action.savedChanges.indexOf(change.id) < 0
            ),
          }
        }
      };
    }
    case 'CLEAR_SAVED_COVERS_CHANGES': {
      return {
        ...state,
        // returns all changes that weren't just saved
        changes: {
          ...state.changes,
          covers: {
            ...state.changes.covers, data: state.changes.covers.data.filter(
              change => action.savedChanges.indexOf(change.id) < 0
            ),
          }
        }
      };
    }
    case 'CLEAR_SAVED_COMMENTS_CHANGES': {
      return {
        ...state,
        // returns all changes that weren't just saved
        changes: {
          ...state.changes,
          comments: {
            ...state.changes.comments, data: state.changes.comments.data.filter(
              change => action.savedChanges.indexOf(change.id) < 0
            ),
          }
        }
      };
    }
    /*** AUTO-SAVE ***/

    /*** ensure sitemap.docs.pages is always up to date ***/
    case 'MERGE_PAGES_EDITS': { // merge = realtime changes from doc
      // case 'UPDATE_PAGES_FROM_FIREBASE_LISTENER': { // update = merged pages from user change
      return {
        ...state, docs: { ...state.docs, pages: action.pages }
      }
    }
    /*** ensure sitemap.docs.pages is always up to date ***/

    /*** ensure sitemap.docs.website_sections is always up to date ***/
    case 'MERGE_WEBSITE_SECTIONS_EDITS': { // merge = realtime changes from doc
      return {
        ...state, docs: { ...state.docs, website_sections: action.website_sections }
      }
    }
    /*** ensure sitemap.docs.website_sections is always up to date ***/

    /*** ensure sitemap.docs.sections is always up to date ***/
    case 'UPDATE_PAGE_SECTIONS_FROM_FIREBASE_LISTENER': {
      return { ...state, docs: { ...state.docs, sections: action.pages } }
    }
    /*** ensure sitemap.docs.sections is always up to date ***/

    /*** MERGING REAL-TIME EDITS FROM OTHER USERS ***/
    case 'MERGE_PAGE_SECTIONS_EDITS': {
      return {
        ...state,
        data: { ...state.data, page_sections: { ...state.data.page_sections, ...action.sections } }
      };
    }
    case 'MERGE_COVERS_EDITS': {
      return {
        ...state,
        covers: { ...state.covers, pages: { ...state.covers.pages, ...action.pages } }
      }
    }
    case 'MERGE_COMMENTS_EDITS': {
      return {
        ...state,
        comments: action.pages,
        members: action.members /* used for new mentions + comment user data */,
      }
    }
    case 'MERGE_SEO_EDITS': {
      return {
        ...state,
        data: { ...state.data, seo: deepmerge(state.data.seo, action.seo) }
      }
    }
    case 'MERGE_scheduled_screenshots_EDITS': {
      return {
        ...state, docs: { ...state.docs, scheduled_screenshots: action.scheduled_screenshots }
      }
    }
    /*** MERGING REAL-TIME EDITS FROM OTHER USERS ***/

    /*** MERGE SITEMAP DOC EDITS ***/
    case 'MERGE_SITEMAP_DOC_EDITS': {
      return action.sitemap
    }
    /*** MERGE SITEMAP DOC EDITS ***/

    case 'TOGGLE_CONTEXTMENU_DROPDOWN': {
      return {
        ...state,
        ui: {
          ...state.ui,
          ContextMenuDropdown: {
            showing: action.showing,
            node: action.node ? action.node : {},
            offset: action.offset ? action.offset : []
          },
        },
      };
    }

    // sitemap is always updated when editor is updated, but not vice versa
    case 'CHANGE_EDITOR_PALLETTE':
    case 'CHANGE_SITEMAP_PALLETTE': {
      return {
        ...state,
        pallette: action.pallette
      }
    }
    case 'TOGGLE_SITEMAP_NOTIFICATIONS_DROPDOWN': {
      return {
        ...state,
        ui: {
          ...state.ui,
          NotificationsDropdown: { showing: action.showing }
        }
      }
    }
    case 'INIT_PAGE_CONTENT_FULFILLED': {
      return {
        ...state,
        content: {
          ...action.payload.data
        }
      };
    }
    /*  case 'ADD_PAGE_CONTENT_SECTION_PENDING': {
       return {
         ...state,
         content: {
           ...state.content,
           sections:
             [...state.content.sections, action.meta.newSection]
         },
         data: {
           ...state.data,
           'page-sections': { ...state.data.page_sections, ...action.meta.pageSection }
         }
       }
     }
     case 'UPDATE_PAGE_CONTENT_SECTION_INDEXES_PENDING': {
       return {
         ...state,
         content: {
           ...state.content,
           sections: action.meta.sections
         },
         data: {
           ...state.data,
           sections: {
             ...state.data.sections,
             [action.meta.pageId]: action.meta.updatedPageSection
           }
         }
       }
     } */
    /* case 'DELETE_PAGE_SECTION': {
      return {
        ...state,
        data: {
          ...state.data,
          sections: action.sections
        }
      }
    } */
    case 'UPDATE_PAGE_SECTIONS_DATA': {
      return {
        ...state,
        data: {
          ...state.data,
          page_sections: action.sections
        }
      }
    }
    case 'TOGGLE_PAGE_SECTIONS_OPTIONS_POPOVER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          PageSectionsOptionsPopover: {
            ...state.ui.PageSectionsOptionsPopover,
            ...action.data
          }
        }
      }
    }
    case 'TOGGLE_WEBSITE_SECTION_OPTIONS_POPOVER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          WebsiteSectionOptionsPopover: {
            ...state.ui.WebsiteSectionOptionsPopover,
            ...action.data
          },
          PageButtons: { showing: false }
        }
      }
    }
    case 'TOGGLE_WEBSITE_SECTION_NEW_BUTTON': {
      return {
        ...state,
        ui: {
          ...state.ui,
          WebsiteSectionNewButton: {
            ...state.ui.WebsiteSectionNewButton,
            ...action.data
          },
          PageButtons: { showing: false }
        }
      }
    }
    case 'UPDATE_SITEMAP_MEMBERS': { return { ...state, members: action.members } }
    case 'SAVE_SEO_CHANGE_FULFILLED': {
      return {
        ...state,
        data: { ...state.data, seo: action.payload.seo },
      };
    }
    case 'TOGGLE_EXPORT_COVERS_PROGRESS_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          ExportCoversProgressModal: {
            ...state.ui.ExportCoversProgressModal,
            showing: action.showing,
            forThumbnail: !action.showing ? null : action.forThumbnail,
            cancelled: action.showing ? false : action.cancelled,
            progress: action.showing ? 5 : state.ui.ExportCoversProgressModal.progress
          },
        },
      };
    }
    case 'UPDATE_EXPORT_MODAL_WITH_PROGRESS': {
      return {
        ...state,
        ui: {
          ...state.ui,
          ExportCoversProgressModal: {
            ...state.ui.ExportCoversProgressModal,
            progress: action.progress
          },
        },
      };
    }
    case 'CHANGE_LINKED_SITEMAP_FULFILLED': {
      const data = !action.payload.sitemap ? model.sitemap : state; // resets data if sitemap unlinked from user flow
      return data;
    }

    /*** Revision History ***/

    case 'TOGGLE_REVISION_HISTORY_DRAWER': {
      return {
        ...state,
        data: {
          ...state.data,
          root: state.data.root,
          heights: action.showing ? state.data.heights : {} // clear heights
        }
      };
    }

    case 'SET_REVISION_HISTORY_DRAWER_SELECTED_INDEX': {
      return {
        ...state,
        data: {
          ...state.data,
          page_sections: action.sections ? action.sections : state.data.sections, // clear page_sections
          heights: {} // clear heights
        }
      };
    }

    case 'RESTORE_REVISION_HISTORY_DRAWER_CHANGES_FULFILLED': {
      return {
        ...state,
        history: model.sitemap.history,
        docs: !isEmpty(action.payload.pages)
          ?
          {
            ...state.docs, pages: action.payload.pages,
            website_sections: action.payload.website_sections,
            sections: action.payload.sections,
          }
          : state.docs
      };
    }

    /*** Revision History ***/

    default:
      return state;
  }
};

export default sitemap;
