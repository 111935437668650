import model from './model';

export default (state = model.people, action) => {
  switch (action.type) {
    case 'GET_PEOPLE_FULFILLED': {
      return {
        ...state,
        byId: { ...state.byId, ...action.payload.byId },
        loaded: true,
      };
    }
    case 'GET_PEOPLE_PENDING': {
      return {
        ...state,
      };
    }
    case 'GET_PEOPLE_REJECTED': {
      return {
        ...state,
        loaded: false,
      };
    }
    case 'MERGE_PEOPLE_CHANGE': {
      return {
        ...state,
        byId: action.byId
      };
    }
    default:
      return state;
  }
};
