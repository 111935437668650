import model from './model';

export default (state = model.folders, action) => {
  switch (action.type) {
    case 'SWITCH_ACCOUNT_PENDING': {
      return {
        ...state,
        ...model.folders,
        loading: true,
        loaded: false,
      };
    }
    case 'GET_FOLDERS_FULFILLED': {
      return {
        ...state,
        byId: { ...state.byId, ...action?.payload?.byId },
        loading: false,
        loaded: true,
      }
    }
    case 'GET_FOLDERS_PENDING': {
      return {
        ...state,
      };
    }
    case 'TOGGLE_NEW_FOLDER_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          NewFolderModal: { showing: action.showing }
        }
      };
    }
    case 'TOGGLE_SHARE_FOLDER_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          ShareFolderModal: { showing: action.showing, folder: action.folder }
        }
      };
    }
    case 'TOGGLE_DELETE_FOLDER_MODAL': {
      return {
        ...state,
        ui: {
          ...state.ui,
          DeleteFolderModal: { showing: action.showing, folder: action.folder }
        }
      };
    }
    case 'CREATE_NEW_FOLDER_PENDING': {
      return {
        ...state,
        ui: {
          ...state.ui,
          NewFolderModal: {
            ...state.ui.NewFolderModal, creating: true
          }
        },
      };
    }
    case 'CREATE_NEW_FOLDER_FULFILLED': {
      return {
        ...state,
        byId: { ...state?.byId, ...action?.payload?.byId },
        // data: [...state.data, action?.payload?.data],
        ui: {
          ...state.ui,
          NewFolderModal: {
            ...state.ui.NewFolderModal, creating: false
          }
        }
      };
    }
    case 'MERGE_FOLDER_CHANGE': {
      return {
        ...state,
        byId: action.byId
      }
    }
    default:
      return state;
  }
};
