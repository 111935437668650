import model from './model';

export default (state = model.auth, action) => {
  switch (action.type) {
    // LOGIN
    case 'LOGIN_FULFILLED': {
      return { ...state, isError: null, loggingIn: false, loggedIn: true };
    }
    case 'LOGIN_PENDING': {
      return { ...state, loggingIn: true, loggedIn: false };
    }
    case 'LOGIN_REJECTED': {
      return {
        ...state,
        isError: action.payload,
        loggingIn: false,
        loggedIn: false,
      };
    }
    // SIGN UP
    case 'SIGNUP_FULFILLED': {
      return { ...state, isError: null, signingUp: false, signedUp: true, loggedIn: true };
    }
    case 'SIGNUP_PENDING': {
      return { ...state, signingUp: true, loggedIn: false };
    }
    case 'SIGNUP_REJECTED': {
      return {
        ...state,
        isError: action.payload,
        signingUp: false,
        loggedIn: false,
      };
    }
    case 'REDIRECT_USER': {
      return { ...state, redirectUser: action.path }
    }
    // LOG OUT
    case 'LOGOUT_FULFILLED': {
      return { ...model.auth, loggedOut: true };
    }
    case 'LOGOUT_PENDING': {
      return { ...state };
    }
    case 'LOGOUT_REJECTED': {
      return { ...state, isError: action.payload };
    }
    // LOG OUT
    case 'INIT_ACCOUNT_FULFILLED': {
      return { ...state, initing: false };
    }
    case 'INIT_ACCOUNT_PENDING': {
      return { ...state, initing: true };
    }
    case 'INIT_ACCOUNT_REJECTED': {
      return { ...state, initing: false, error: action.payload };
    }
    case 'SWITCH_ACCOUNT_FULFILLED': {
      return {
        ...state,
        switching: {},
      };
    }
    case 'SWITCH_ACCOUNT_PENDING': {
      return {
        ...model.auth,
        switching: { account: true },
      };
    }
    case 'SWITCH_ACCOUNT_REJECTED': {
      return { ...state, switching: {}, isError: action.payload };
    }
    case 'SWITCH_TEAM_FULFILLED': {
      return {
        ...state,
        switching: { team: false }
      }
    }
    case 'SWITCH_TEAM_PENDING': {
      return {
        ...state,
        switching: { team: action.meta.teamId },
      };
    }
    case 'SWITCH_TEAM_REJECTED': {
      return {
        ...state,
        switching: {},
        isError: action.payload,
      };
    }
    default:
      return state;
  }
};
