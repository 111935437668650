import { useGetAnnualPlanPrice, useGetPlanId, useGetShortPlanId, usePricingContext } from '.';
import { useGetSubscription, useInAgencyPlan, useInEnterprisePlan, useInOrganization, useInProPlan, useInTeamPlan, useIsCreatorOfOrganization } from '../../src/hooks';

import dayjs from "dayjs";
import getSymbolFromCurrency from 'currency-symbol-map'
import { isInMarketing } from '../helpers';

export const TRIAL_DAYS = 14;

export const useGetNumberOfSeatsIncludedInPlan = ({ planId } = {}) => {
    const { pricing } = usePricingContext()
    const shortPlanId = useGetShortPlanId(planId)
    if (!isInMarketing) planId = planId || shortPlanId
    const isInTeamPlan = useInTeamPlan(planId)
    const isInAgencyPlan = useInAgencyPlan(planId)
    if (!isInMarketing && isInTeamPlan) return 5; // legacy
    // if (!isInMarketing && isInAgencyPlan) return Infinity; // legacy
    if (isInAgencyPlan) return "Unlimited"; // unlimited for new Agency plan
    const { seats } = pricing?.plans?.[planId]?.limits || { seats: 5 }
    return seats;
}

export const useGetCurrency = () => {
    const plan = useGetSubscription()?.plan || {}
    const { currency } = !isInMarketing ? plan : {};
    const { selectedCurrency } = usePricingContext() || {}
    // use plans currency if available, or currency stored from ipapi (selectedCurrency)
    return (currency || selectedCurrency)?.toLowerCase()
}

export const useGetAnnualDiscount = ({ price }) => {
    const annualPrice = useGetAnnualPlanPrice(price)
    const monthlyPriceOver12Months = Math.round(price * 12)
    return (monthlyPriceOver12Months - annualPrice).toFixed(2)
}

export const useGetMonthlyAnnualPlanPrice = (annualPrice) => ((annualPrice / 12).toFixed(2));

export const useIsBilledMonthly = () => {
    return useGetOrganizationPlanPriceInUSD() % 10
}

export const useIsBilledYearly = () => !useIsBilledMonthly()

export const useGetOrganizationPlanPriceInUSD = () => {
    return parseInt(useGetPlanId()?.split('-')?.pop()) || 15
}

export const useGetPlanPriceByCurrency = ({ price = 15, currency = "USD" }) => {
    return currenciesByMultiPlansPrice({ price, currency })
}

export const useGetLocalisedCurrencySymbol = () => {
    const currency = useGetCurrency()?.toUpperCase()
    const symbol = getSymbolFromCurrency(currency);
    return symbol
}

export const useShowCurrencyForDollarSymbol = () => {
    const currency = useGetCurrency()?.toUpperCase()
    const symbol = getSymbolFromCurrency(currency);
    if (symbol === '$') return currency
}

export const useGetPriceWithCurrencySymbol = (price) => {
    if (!price) price = 0
    const currency = useGetCurrency()
    const currencySymbol = useGetLocalisedCurrencySymbol(currency)
    return `${currencySymbol}${price?.toLocaleString() || 0}`
}

export const useGetMonthlyPlanPrice = ({ planId }) => {
    const { pricing } = usePricingContext()
    const currency = useGetCurrency()
    return pricing?.plans?.[planId]?.prices?.[currency] || 0
}

export const useGetMonthlyPlanPriceWithAddons = ({ planId, seats }) => {
    const monthlyPrice = useGetMonthlyPlanPrice({ planId })
    const seatPrice = useGetAdditionalSeatPricing()
    let number_of_seats = useGetNumberOfSeatsIncludedInPlan({ planId })
    if (typeof number_of_seats !== Number) number_of_seats = Infinity // for unlimited
    const number_of_seats_over_limit = useGetNumberOfSeatsOverLimit(number_of_seats, seats)
    return monthlyPrice + (seatPrice * (number_of_seats_over_limit))
}

// number_of_seats is what we have, seats is what we want
export const useGetNumberOfSeatsOverLimit = (number_of_seats, seats) => Math.abs(number_of_seats > seats ? 0 : seats - number_of_seats)

export const useGetPagesImportLimitByPlan = ({ planId } = {}) => {
    const { pricing } = usePricingContext()
    const { pages_per_import } = pricing?.plans?.[planId]?.limits || { pages_per_import: 50 }
    return pages_per_import;
}

export const useGetScreenshotsLimitByPlan = ({ planId } = {}) => {
    const { pricing } = usePricingContext()
    const shortPlanId = useGetShortPlanId()
    planId = planId || shortPlanId
    const { screenshots } = pricing?.plans?.[planId]?.limits || { screenshots: 50 }
    return screenshots;
}

export const useGetAdditionalSeatPricing = () => {
    const { pricing } = usePricingContext() || {}
    const { prices } = pricing?.add_ons?.seats || {}
    const currency = useGetCurrency()
    return prices?.[currency]
}

const pricingByPlan = {
    pro: 15,
    agency: 249,
    team: 49,
    enterprise: 59,
}

export const useGetLocalisedPlanPriceById = (planId) => {
    const price = pricingByPlan[planId]
    const currency = useGetCurrency()
    return currenciesByMultiPlansPrice({ price, currency })
}

const currenciesByMultiPlansPrice = ({ price, currency }) => {
    price = !price ? 0 : price; // fail-safe
    currency = currency?.toLowerCase() || 'usd' // fail-safe
    const prices = {
        15: {
            usd: 15,
            gbp: 12,
            eur: 13,
            aud: 20,
            nzd: 20
        },
        59: {
            usd: 59,
            gbp: 47,
            eur: 51,
            aud: 79,
            nzd: 79
        },
        149: {
            usd: 149,
            gbp: 119,
            eur: 129,
            aud: 199,
            nzd: 199
        }
    }
    return prices?.[price]?.[currency] || price
}

export const useIsTrialEnabled = () => {
    const { is_trial_enabled } = usePricingContext()?.pricing?.options || {}
    return is_trial_enabled
}

export const useIsTrialing = () => {
    return useGetSubscription()?.status === 'trialing'
}

export const useCanStartNewTrial = () => {
    const isTrialEnabled = useIsTrialEnabled()
    const inOrganization = useInOrganization()
    const isCreatorOfOrganization = useIsCreatorOfOrganization()
    return isTrialEnabled && !inOrganization && !isCreatorOfOrganization
}

export const useGetBillingInterval = () => {
    return useGetSubscription()?.plan?.interval
}

export const useIsEnterprisePlanBetterValue = ({ planId, seats }) => {
    const forProPlan = useInProPlan(planId)
    const proPriceWithAddons = useGetMonthlyPlanPriceWithAddons({ planId: 'team', seats })
    const enterprisePrice = useGetMonthlyPlanPriceWithAddons({ planId: 'agency', seats: 0 })
    if (forProPlan) {
        if (proPriceWithAddons >= enterprisePrice) return true;
    }
    return;
}

export const useGetPlanLimits = planId => {
    const { pricing } = usePricingContext()
    return (pricing?.plans?.[planId?.toLowerCase()] || {}).limits
}

export const useGetNumberOfDaysLeftInTrial = () => {
    const dueDate = useGetTrialEndDate()
    return dayjs(dueDate).diff(new Date(), 'day')
}

export const useGetTrialEndDate = () => {
    const isTrialing = useIsTrialing()
    const { current_period_end } = useGetSubscription()
    return isTrialing ? dayjs.unix(current_period_end) : dayjs(new Date()).add(TRIAL_DAYS, 'day')
}

export const useIsTrialingOrCanStartNewTrial = () => {
    const isTrialing = useIsTrialing()
    const canStartNewTrial = useCanStartNewTrial()
    return isTrialing || canStartNewTrial
}

export const useShowAnnualBilling = () => {
    const { billingInterval } = usePricingContext()
    return billingInterval === 'year'
}