import * as style from '@dicebear/avatars-initials-sprites'

import { HUBSPOT_PORTAL_ID, wait } from '../../shared/helpers'
import { collection, doc, getFirestore } from "firebase/firestore/lite"
import { compact, isNil, keys } from 'lodash'

import { createAvatar } from '@dicebear/avatars'
import { store } from '../store'

export const motionPropsZeroAnimation = {
  // Configure the initial state pre-transition (optional)
  initial: {
    visibility: "hidden",
    opacity: 0,
    scale: 1,
  },
  variants: {
    enter: {
      visibility: "visible",
      opacity: 1,
      scale: 1,
      transition: {
        // If you want to completely get rid of the animation, just change this duration to 0
        duration: 0, // 0.3,
        ease: [0.4, 0, 0.2, 1],
      },
    },
    exit: {
      transitionEnd: {
        visibility: "hidden",
      },
      opacity: 0,
      scale: 1,
      transition: {
        // Same with this duration on exit animation
        duration: 0, // 0.1,
        easings: "easeOut",
      },
    },
  },
}

export const toTitleCase = (str = "") => {
  return (str || "")?.replace(/(^|\s)\S/g, (t) => t?.toUpperCase());
}

export const getLocation = () => window.location;

export const getInTrash = () => {
  const { pathname } = window.location;
  return pathname?.endsWith("/trash")
}

export const getIsNavbarHidden = () => {
  return getUI()?.navbar?.hidden
}

export const getIsUserFlowLinkedToSitemap = () => {
  const inUserFlow = getInUserFlow()
  const flow = getUserFlow()
  return (inUserFlow && flow?.sitemap) ? true : false;
}

export const getColorMode = () => getUI()?.colorMode

export const getFolderThumbnail = (files) => {
  const colorMode = getColorMode()
  const fileWithThumbnail = files.find(fi => fi?.thumbnail?.images?.[colorMode]); // this should automatically be the first file with a thumbnail as sorted by users preference
  const thumbnail = fileWithThumbnail?.thumbnail?.images?.[colorMode];
  return thumbnail;
}

export const getUserId = () => getUser()?.token?.claims?.user_id
export const getUser = () => store.getState()?.user

export const getOrganizations = () => store.getState()?.organizations
export const getOrganizationId = () => getUser()?.token?.claims?.organization
export const getOrganization = () => {
  return store.getState()?.organization
}

export const getInUserSitemap = () => {
  const userId = getUserId()
  const editorDocId = getEditorDocIdFromPath()
  return editorDocId === userId
}

export const getInSitemap = () => {
  return window.location.pathname.includes('/sitemap/');
}

export const getInUserFlow = () => {
  return window.location.pathname.includes('/flow/');
}

export const getInOrganization = () => {
  return getUser()?.token?.claims?.organization ? true : false
}

export const getEditor = () => {
  return store.getState().editor;
}

export const getSitemap = () => {
  const { sitemap } = store.getState()
  const isTemplateDrawerOpen = getIsTemplateDrawerOpen()
  return !isTemplateDrawerOpen ? sitemap : (getTemplateDrawer()?.sitemap || {})
}

export const getUI = () => {
  return store.getState().ui;
}

export const getUserFlow = () => {
  return store.getState().flow;
}

export const getInEditor = () => {
  return (getInSitemap() || getInUserFlow())
}

export const getEditorDocIdFromPath = () => {
  const { pathname } = window.location;
  if (getInEditor()) return pathname.split("/").pop();
}

export const getEditorCollectionFromPath = () => {
  const { pathname } = window.location;
  if (pathname.includes('/sitemap/')) return 'sitemaps'
  if (pathname.includes('/flow/')) return 'user-flows'
}

export const getEditorSubfolderFromDoc = (item) => item?.collection?.split("-")?.pop()?.slice(0, -1)?.toLowerCase()

export const getCollectionLabelFromDoc = (item) => item?.collection?.replace("-", " ")?.slice(0, -1)

export const getItemNameFromDoc = (item) => {
  const name = item?.collection?.replace("-", " ")?.slice(0, -1)
  return toTitleCase(name?.charAt(0) + name?.slice(1))
}

export const getAvatarSrc = (props) => {
  const { user, organization } = props;
  // return null;
  if (user) {
    if (user.photoURL) return user.photoURL;
    const text = (user?.firstName ? `${user?.firstName?.[0]} ${user.lastName?.[0]}` : user?.email)?.toLowerCase();
    let svg = createAvatar(style, { seed: text === 'imported' ? "+" : text, chars: 2, scale: 100 });
    return "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)));
  }
  if (organization) {
    let svg = createAvatar(style, { seed: organization?.name?.toLowerCase(), chars: 2 });
    return "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)));
  }
};

export const isValidURL = str => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(str);
  // eslint-disable-next-line
  var isValidUrl = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return isValidUrl.test(str);
};

export const isValidEmail = email => {
  /* eslint-disable-next-line */
  var pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // fragment locator
  return pattern.test(email);
};

export const getFavicon = (sitemap) => {
  if (isValidURL(sitemap?.domain)) return `https://www.google.com/s2/favicons?sz=128&domain_url=${sitemap?.domain}`;
  return 'error';
};

export const getFaviconError = (e, sitemap) => {
  var sitemapName = sitemap?.name?.toLowerCase() || 'Sitemap';
  sitemapName = sitemapName.replace(/(^\w+:|^)\/\//, '').replace('www.', '') // replace protocols from sitemap name (so there isn't a load of H avatars)
  let svg = createAvatar(style, { seed: sitemapName, chars: 1 });
  return "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)));
};

export const convertObjToArrayWithKey = (obj, key = 'id') => {
  return compact(keys(obj)?.map(k => (obj[k] ? { [key]: k, ...obj[k] } : obj[k])))
}

export const getEncodedEmail = (email) => {
  return encodeURIComponent(getDecodedEmail(email)).replace(/\./g, '%2E')
}

export const getDecodedEmail = (email) => {
  return decodeURIComponent(email).replace(/%2E/g, '.');
}

export const removeDuplicateQueryParams = (url) => {
  let [path, params] = url.split("?");
  return path + '?' + new URLSearchParams(Object.fromEntries(new URLSearchParams(params))).toString()
}

export const getUsersFullName = (user) => {
  user = user || getUser() // if user not supplied, use actual user
  return user?.firstName ? (`${user?.firstName} ${user?.lastName || ''}`) : user?.email;
}

export const getPhotoURL = (user) => (user || getUser())?.photoURL

export const setDefaultsForSharedURL = (sitemap, inSitemapInEditor) => {
  // get format / showCovers if in editor, else from file
  const { format, showCovers } = inSitemapInEditor ? getSitemap() : sitemap
  const device = sitemap?.covers?.device || 'desktop'
  let query = "";
  // view
  query += "&v=";
  switch (format) {
    case 'tree-vertical':
      query += "tv";
      break;
    case 'tree-vertical-matrix':
      query += "tvm";
      break;
    case 'tree':
      query += "t";
      break;
    case 'indent':
      query += "i";
      break;
    case 'nodes':
      query += "n";
      break;
    default:
      break;
  }
  // show covers
  query += `&sc=${showCovers ? 1 : 0}`;
  // device
  query += `&d=${device[0]}`; // first letter
  // expand all pages
  query += `&ea=1`;
  // return
  return query;
};

export const getCoverData = ({ sitemap, preview, page, device }) => {
  if (preview) return preview;
  if (!sitemap?.covers?.pages?.[page]?.devices?.[device || sitemap?.covers?.device]) return;
  const pages = sitemap?.covers?.pages?.[page] || {}
  return pages?.devices[device || sitemap?.covers.device] || null;
}

// get an image blob from url using fetch
export const getImageBlob = async (url) => {
  let errorCount = 0;
  async function getImage(url) {
    try {
      let response = await fetch(url, { cache: 'force-cache' });
      let blob = await response.blob();
      return blob;
    } catch (e) {
      if (errorCount < 2) {
        errorCount++
        await wait(500 * errorCount); // wait half a second
        return getImage(url); // try again (once)
      } else {
        console.log(`Unable to retrieve: ${url}`)
      }
    }
  }
  return getImage(url);
};

// convert a blob to base64
export const blobToBase64 = function (blob) {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.onload = function () {
      let dataUrl = reader.result;
      resolve(dataUrl);
    };
    reader.readAsDataURL(blob);
  });
}

// combine the previous two functions to return a base64 encode image from url
export const getBase64Image = async (url) => {
  try {
    let blob = await getImageBlob(url);
    let base64 = await blobToBase64(blob);
    return base64;
  } catch (e) {
    console.error(e);
  }
}

/*** pallette header ***/
export const defaultPalletteColors = ['#4A5568', '#E0E6EC'];

export const getIsDefaultPalletteHeader = (header) => {
  return (!header || defaultPalletteColors?.includes(header))
}

export const getDefaultPalletteHeader = (colorMode) => {
  colorMode = colorMode || getColorMode()
  return colorMode === "light" ? defaultPalletteColors[0] : defaultPalletteColors[1]
}
/*** pallette header ***/

export const getRevisionHistoryDrawer = () => {
  return getEditor()?.ui?.RevisionHistoryDrawer || {}
}

export const getPageDrawer = () => {
  return getSitemap()?.ui?.PageDrawer || {}
}

export const getPageCommentsDrawer = () => {
  return getSitemap()?.ui?.PageCommentsDrawer || {}
}

export const getCanvasTextEditor = () => {
  return getEditor()?.ui?.CanvasTextEditor || {}
}

export const getIsLinkedUserFlowPage = (node) => {
  return getInUserFlow() && getIsUserFlowLinkedToSitemap() && node?.page
}

export const getIsViewingFolder = () => {
  const { pathname } = window.location
  return pathname.includes('/folder/')
}

export const getFolderIdFromPath = (opts = {}) => {
  const { returnAccountId } = opts;
  const { pathname } = window.location
  // return folder if in one
  if (getIsViewingFolder()) return pathname.split("/").pop()
  // return accountId if not in folder
  if (returnAccountId) return getAccountId()
}

export const getNewFirestoreDocId = (collectionId, opts) => {
  const { newDocId } = opts || {}
  const inOrganization = getInOrganization()
  if (!inOrganization && !newDocId) return getUserId(); // override returning user id and return a new doc id instead (used for sitemap import)
  const firestore = getFirestore()
  return doc(collection(firestore, collectionId))?.id
}

export const getAccountId = () => {
  const organizationId = getOrganizationId()
  const userId = getUserId()
  return organizationId || userId
}

export const getIsItemOwner = (item) => {
  const userId = getUserId()
  return item?.createdBy === userId
}

export const getIsPublicFolder = (folder, opts = {}) => {
  const { excludeOwner = false } = opts
  const userId = getUserId()
  return folder?.members === null && (excludeOwner ? folder?.createdBy !== userId : true)
}

export const getIsFolderSharedWithUser = (folder, opts = {}) => {
  const { excludeOwner = false } = opts
  const userId = getUserId()
  const isOwner = getIsItemOwner(folder)
  return (excludeOwner ? !isOwner : true) && folder?.members?.includes(userId)
}

export const getIsPublicFolderOrSharedWithUser = (folder, opts = {}) => {
  return getIsPublicFolder(folder, opts) || getIsFolderSharedWithUser(folder, opts)
}

export const getDoesUserHavePathToFolder = (folder, foldersById) => {
  let hasPath = true
  function recurse(f) {
    const parent = foldersById?.[f?.team]
    // check for team (top-level folders will automatically have path for user)
    if (f?.team) {
      if (!parent) hasPath = false; return;
    } else {
      if (parent) recurse(parent)
    }
  }
  recurse(folder)
  return hasPath
}

export const getPeople = () => {
  return store.getState()?.people || {}
}

export const getPeopleData = (opts = {}) => {
  const { onlyMember } = opts
  const data = convertObjToArrayWithKey(getPeople()?.byId);
  if (onlyMember) return data?.filter(d => d?.firstName) // users won't have a first name if they don't have an account yet 
  return data
}

export const isFirebaseStorageURL = (url) => {
  return url?.includes('firebasestorage')
}

export const getImportingWebsiteLabel = (d) => {
  return !isFirebaseStorageURL(d?.url) ? d?.url : (d?.domain || d?.fileName || "--")
}

export const getTemplateDrawer = () => {
  return store.getState()?.ui?.TemplateDrawer
}

export const getIsTemplateDrawerOpen = () => {
  return getTemplateDrawer()?.showing
}

/*** Hubspot ***/
export const sendHubspotCustomEvent = (name, properties = {}) => {
  let _hsq = window._hsq = window._hsq || [];
  Object.keys(properties || {})?.forEach(key => { if (isNil(properties[key])) properties[key] = ''; }) // ensure we send empty string for empty properties
  try {
    _hsq.push(["trackCustomBehavioralEvent", { name: `pe${HUBSPOT_PORTAL_ID}_${name}`, properties }])
  } catch (e) {
    console.error(e)
  }
}
/*** Hubspot ***/