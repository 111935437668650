// import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage'; // compat (v8)
import 'firebase/storage'; // for seo doc (new modular v9)

import { getEnv, isInDev, isInMarketing } from '../shared/helpers';

import firebase from 'firebase/compat/app';

// import 'firebase/compat/performance';
// import 'firebase/compat/analytics';


const environment = getEnv()

var config = {
    dev: {
        apiKey: "AIzaSyCMJaSDBdgfK6UJQ8qOuXP30ivI6drxKxQ",
        authDomain: "rarchy-175303.firebaseapp.com",
        databaseURL: "https://rarchy-175303.firebaseio.com",
        projectId: "rarchy-175303",
        storageBucket: "rarchy-175303.appspot.com",
        messagingSenderId: "290248084603",
        appId: "1:290248084603:web:39f8100d7204a483",
        measurementId: "G-VPVYCQDLCJ"
    },
    testing: {
        apiKey: "AIzaSyAr5qZ9oXqdFipsp7yZOAqybo3GvGy9MG8",
        authDomain: "rarchy-testing-f230d.firebaseapp.com",
        databaseURL: "https://rarchy-testing-f230d.firebaseio.com",
        projectId: "rarchy-testing-f230d",
        storageBucket: "",
        messagingSenderId: "217853506224",
        appId: "1:217853506224:web:66180e296daebeee"
    },
    prod: {
        apiKey: "AIzaSyD1rC1Z8YQJ_8t0JO3jccR2pW-nbE3KrBg",
        authDomain: "rarchy.com",
        databaseURL: "https://rarchy-production.firebaseio.com",
        projectId: "rarchy-production",
        storageBucket: "rarchy-production.appspot.com",
        messagingSenderId: "623296379059",
        appId: "1:623296379059:web:647199fea273cd23",
        measurementId: "G-0WSPFQZX0N"
    }
};

const app = firebase.initializeApp(config[environment]);

// this has to be here for nextJS
if (!isInMarketing) {

    firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true, experimentalAutoDetectLongPolling: true })

    // allows us to read and insert the token into the user object a sec or so quicker
    firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
            const { store } = await import('./store');
            const { initAccount } = await import('./store/actions/auth-actions');
            const IdTokenResult = await user.getIdTokenResult();
            store.dispatch({ type: 'REFRESHED_TOKEN', payload: IdTokenResult });
            store.dispatch(initAccount({ user: { id: user?.uid, email: user.email, token: IdTokenResult } }))
        }
    })

}

// enable persistence
/* firebase.firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch(function (err) {
        console.error(err);
        if (err.code === 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code === 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    }); */

/*** emulate functions when running on local desktop (yarn start) ***/
if (!isInMarketing && isInDev) {
    console.log("using local emulator");
    app.functions().useFunctionsEmulator('http://localhost:5000');
}
/*** emulate functions when running on local desktop (yarn start) ***/

export default firebase;