export const links = [
    {
        title: 'Products',
        links: [
            {
                label: 'Visual Sitemaps',
                href: '/sitemaps',
            },
            {
                label: 'Sitemap Generator',
                href: '/sitemaps/visual-sitemap-generator',
            },
            {
                label: 'User Flows',
                href: '/user-flow',
            }
        ],
    },
    {
        title: 'Demos',
        links: [
            {
                label: 'Demo Sitemap',
                href: '/app/sitemap/fuy2mjJWjKIof5oiacCs',
            },
            {
                label: 'Large Sitemap',
                href: '/app/sitemap/pCqbPjVeDyNDvBD59syn',
            },
            {
                label: 'Agency Template',
                href: '/app/sitemap/kTJH1jkyCpG6IMlNLapq',
            }
        ],
    },
    {
        title: 'About',
        links: [
            {
                label: 'Pricing',
                href: '/pricing',
            },
            {
                label: 'Support',
                href: '/support',
            },
            {
                label: 'Log In',
                href: '/app',
            },
        ],
    },
    {
        title: 'Legal',
        links: [
            {
                label: 'Terms of Service',
                href: '/terms-of-service',
            },
            {
                label: 'Privacy Policy',
                href: '/privacy-policy',
            },
            {
                label: 'Cookie Policy',
                href: '/cookie-policy',
            },
        ],
    },
]