import { Box, Center, Image, Stack, Text } from '@chakra-ui/react'
import { Container } from '../../-components/Container'
import Marquee from "react-fast-marquee";
import * as logos from './logos'

const Logos = () => {
    return (
        <Container py={{ base: '12', md: '16' }}>
            <Stack spacing="12" align="center">
                <Text
                    maxW={{ base: 'xs', md: 'unset' }}
                    fontSize={{ base: 'md', md: 'lg' }}
                    fontWeight="medium"
                    color="fg.muted"
                    textAlign="center"
                >
                    Rarchy is trusted by thousands of companies worldwide
                </Text>
                <Marquee autoFill pauseOnHover pauseOnClick gradient>
                    {Object.entries(logos).map(([name, Logo]) => {
                        return (
                            <Center key={name} filter="grayscale(1)" opacity={0.9} mr={20}>
                                <Image src={Logo} w="full" h={{ base: '6', md: '8' }} alt={`${name} Logo`} />
                                {/* <Logo h={{ base: '6', md: '8' }} fill="#95928E" /> */}
                            </Center>
                        )
                    })}
                </Marquee>
            </Stack>
        </Container>
    )
}

export default Logos