import { cloneDeep, concat, isEmpty } from 'lodash';

import deepmerge from 'deepmerge';
import model from './model';

export default (state = model.flow, action) => {

    switch (action.type) {

        case 'INIT_USER_FLOW_PENDING': {
            return {
                ...state,
                ...action.meta
                // never put loading: true here
            };
        }
        case 'INIT_USER_FLOW_FULFILLED': {
            return {
                ...state
            }
        }
        case 'INIT_USER_FLOW_ELEMENTS_DOC_FULFILLED': {
            const { doc, ...rest } = action.payload; // don't include 'doc' when spreading
            return {
                ...state,
                // data: { ...state.data, ...rest.data },
                docs: deepmerge(state.docs, rest.docs), // deepmerge to ensure old data is coming through
                // loading: false,
                // loaded: true // (test) // (state.id === 'new' || state.id === 'generate') ? true : false // ensures that /sitemap/new loads as quickly as possible (sitemap usually loads on covers loaded)
            };
        }

        case 'SET_USER_FLOW_TO_LOADED': {
            return {
                ...state,
                loading: false,
                loaded: true
            }
        }

        case 'CHANGE_LINKED_SITEMAP_FULFILLED': {
            return {
                ...state,
                sitemap: action.payload?.sitemap
            }
        }
        /* case 'INIT_USER_FLOW_PENDING': {
            return {
                ...state,
                selected: action?.meta?.id,
                history: model.flows.history, // reset undo/redo,
                changes: model.flows.changes, // reset changes
                byId: {
                    ...state.byId,
                    [action.meta.id]: {
                        ...state.byId[action.meta.id]
                    }
                }
            }
        }
        case 'INIT_USER_FLOW_REJECTED': {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.id]: {
                        loading: false,
                        loaded: true,
                        error: action.payload.code
                    }
                },
            };
        } */
        case 'DELETE_USER_FLOW_FULFILLED': {
            const { flowId } = action.payload;
            const byId = { ...state.byId };
            delete byId[flowId]
            const selected = state.selected === flowId ? null : state.selected;
            return { ...state, selected, byId };
        }
        case 'DELETE_USER_FLOW_PENDING': {
            const { flowId } = action.meta;
            const byId = state.byId;
            delete byId[flowId]
            return { ...state, byId };
        }
        case 'DRAGGING_NEW_SYMBOL': {
            return {
                ...state,
                utils: {
                    ...state.utils,
                    NewSymbol: {
                        dragging: action.dragging,
                        symbol: action.symbol
                    }
                }
            }
        }
        /* case 'INSERT_NEW_SYMBOL':
        case 'CHANGED_CONNECTOR':
        case 'CHANGED_SYMBOL': {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [state.selected]: {
                        ...state.byId[state.selected],
                        hideEmptyFlowMessaging: true,
                        data: action.data
                    }
                }
            }
        } */
        /*** AUTO-SAVE ***/
        case 'ADD_FLOW_CHANGE': {
            const undo = cloneDeep(state.history.undo);
            if (action.history) undo.push(action.history);
            return {
                ...state,
                history: {
                    undo: undo,
                    redo: action.history ? [] : state.history.redo,
                },
                changes: {
                    ...state.changes,
                    data: concat(...state.changes.data, action.change)
                },
            };
        }
        /*** SAVE CHANGES ***/
        case 'SAVE_FLOW_ELEMENTS_CHANGES_FULFILLED': {
            return {
                ...state,
                changes: {
                    ...state.changes,
                    saving: false,
                    error: null,
                    errorCount: 0
                }
            }
        }
        case 'SAVE_FLOW_ELEMENTS_CHANGES_PENDING': {
            return {
                ...state,
                changes: {
                    ...state.changes,
                    saving: true,
                }
            }
        }
        case 'SAVE_FLOW_ELEMENTS_CHANGES_REJECTED': {
            return {
                ...state,
                changes: {
                    ...state.changes,
                    saving: false,
                    error: action.error,
                    errorCount: state.changes.errorCount + 1
                }
            }
        }
        /*** SAVE CHANGES ***/
        case 'CLEAR_SAVED_FLOW_CHANGES': {
            return {
                ...state,
                // returns all changes that weren't just saved
                changes: {
                    ...state.changes,
                    data: state.changes.data.filter(
                        change => action.savedChanges.indexOf(change.id) < 0
                    ),
                }
            };
        }
        /*** AUTO-SAVE ***/
        case 'UNDO_FLOW_USER_CHANGE': {
            return {
                ...state,
                history: {
                    undo: action.undo,
                    redo: action.redo,
                },
            };
        }
        case 'REDO_FLOW_USER_CHANGE': {
            return {
                ...state,
                history: {
                    undo: action.undo,
                    redo: action.redo,
                },
            };
        }
        case 'OVERRIDE_FLOW_DATA': {
            const { data } = action;
            return {
                ...state,
                ...data
            };
        }
        /*** OPTIONS POPOVER ****/
        case 'SHOW_FLOW_OPTIONS_POPOVER': {
            const { nodes, links, offset, newSymbolOptions, symbolOptions, connectorOptions } = action;
            return {
                ...state,
                ui: {
                    ...state.ui,
                    OptionsPopover: {
                        showing: true, nodes, links, offset, newSymbolOptions, symbolOptions, connectorOptions
                    }
                },
            };
        }
        case 'HIDE_FLOW_OPTIONS_POPOVER': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    OptionsPopover: { offset: [], showing: false }
                }
            };
        }
        /*** OPTIONS POPOVER ****/

        /*** ensure flow.docs.elements is always up to date ***/
        case 'MERGE_FLOW_EDITS': {
            return {
                ...state, docs: { ...state.docs, elements: action.elements }
            }
        }
        /*** ensure flow.docs.elements is always up to date ***/

        case 'SHOWING_NEW_CONNECTOR_LINE': {
            return {
                ...state,
                utils: { ...state.utils, NewConnectorLine: action.props, mouseoverSymbol: null, mouseoverConnector: null, mouseoverIcon: null }
            }
        }

        case 'SHOWING_MULTI_SELECT_BOX': {
            return {
                ...state,
                utils: { ...state.utils, MultiSelectBox: action.props, mouseoverSymbol: null, mouseoverConnector: null, mouseoverIcon: null }
            }
        }

        case 'MOUSEOVER_ICON': {
            return {
                ...state,
                utils: { ...state.utils, mouseoverIcon: action.mouseover }
            }
        }

        case 'MOUSEOVER_SYMBOL': {
            return {
                ...state,
                utils: { ...state.utils, mouseoverSymbol: action.symbol }
            }
        }

        case 'MOUSEOVER_CONNECTOR': {
            return {
                ...state,
                utils: { ...state.utils, mouseoverConnector: action.connector }
            }
        }

        case 'SET_USER_FLOW_SYMBOLS_AND_CONNECTORS': {
            return {
                ...state,
                data: { ...state.data, nodes: action.nodes, links: action.links }
            };
        }

        case 'TOGGLE_USER_FLOW_SYMBOL_BUTTONS': {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    SymbolButtons: {
                        ...state.ui.SymbolButtons,
                        showing: action.showing,
                        node: action.node,
                        section: action.section,
                        zooming: action.zooming ? true : false
                    }
                }
            };
        }

        /*** SWITCHING ***/

        case 'SWITCH_ACCOUNT_FULFILLED': {
            return { ...model.flow };
        }

        /*** SWITCHING ***/

        /*** Revision History ***/

        case 'RESTORE_REVISION_HISTORY_DRAWER_CHANGES_FULFILLED': {
            return {
                ...state,
                history: model.flow.history,
                docs: !isEmpty(action.payload.elements) ?
                    { ...state.docs, elements: action.payload.elements }
                    : state.docs
            };
        }

        /*** Revision History ***/

        default:
            return state;
    }
};
